import type { ColumnHelper } from '@tanstack/react-table';
import type { TFunction } from 'i18next';

import {
  ArrowExpandIcon,
  DefaultInfluencerIcon,
  MoneyIcon,
} from '../../../assets';
import type {
  CampaignTable,
  InfluencerCampaignStatusType,
  PlatformType,
} from '../../../types';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import { StatusText } from '../../common/StatusText';
import { BrandImage } from '../../images/BrandImage';
import { SocialNetworkImage } from '../../images/SocialNetworkImage';
import { UsersImages } from '../../images/UserImages';
import DTBoldCell from '../DTBoldCell';
import { DTCell } from '../DTCell';
import DTHeader from '../DTHeader';

type GetCampaignsTableHeadersProps = {
  columnHelper: ColumnHelper<CampaignTable>;
  handleChangeSortDirection: (id: string) => void;
  t: TFunction;
  extras: ExtraInfoCampaignHeader;
};

export type ExtraInfoCampaignHeader = {
  isInfluencer: boolean;
};

export const getCampaignsRecurrentTableHeaders = ({
  columnHelper,
  handleChangeSortDirection,
  t,
  extras: { isInfluencer },
}: GetCampaignsTableHeadersProps) => [
  columnHelper.accessor('name', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (info) => {
      if (!info.row.getCanExpand() || isInfluencer)
        return <DTBoldCell content={info.getValue()} id={info.cell.id} />;
      return (
        <button
          {...{
            onClick: (e) => {
              e.stopPropagation();
              const expand = info.row.getToggleExpandedHandler();
              expand();
            },
            style: { cursor: 'pointer' },
          }}
        >
          <div className="flex flex-row">
            <div
              className={`flex items-center justify-center ${info.row.getIsExpanded() ? 'rotate-90' : ''}`}
            >
              <ArrowExpandIcon />
            </div>
            <DTBoldCell content={info.getValue()} id={info.cell.id} />
          </div>
        </button>
      );
    },
    header: ({ header, ...info }) => {
      return (
        <DTHeader
          sortable
          first
          onClick={() => handleChangeSortDirection(header.id)}
          title={t('campaigns.header1')}
          sorting={(info as any).orderBy}
          sortingDirection={(info as any).orderDirection}
          accessor="name"
        />
      );
    },
  }),
  columnHelper.accessor(isInfluencer ? 'brand.name' : 'users', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (info) => {
      if (!isInfluencer) {
        const val = info.getValue() as { profile_picture: string }[];
        return (
          <UsersImages
            size="size-[35px]"
            userCount={val?.length || 0}
            userImages={val.map((v) => v.profile_picture) || []}
          />
        );
      }
      return (
        <div className="flex w-full justify-start flex-row gap-x-2 items-center">
          <BrandImage
            size="size-[50px]"
            image={info.row.original.brand.profile_picture}
          />
          <p>{info.getValue() as string}</p>
        </div>
      );
    },
    header: ({ header }) => (
      <DTHeader
        onClick={() => handleChangeSortDirection(header.id)}
        title={
          isInfluencer
            ? t('campaigns.header2-client')
            : t('campaigns.header2-influencer')
        }
        accessor={isInfluencer ? 'brand_name' : 'influencers'}
      />
    ),
  }),
  columnHelper.accessor('status', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('campaigns.header3')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="status"
      />
    ),
    cell: (info) => <StatusText type="campaign" status={info.getValue()} />,
  }),
  columnHelper.accessor('price', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header, ...info }) => (
      <DTHeader
        sortable
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('campaigns.header4')}
        sorting={(info as any).orderBy}
        sortingDirection={(info as any).orderDirection}
        accessor="price"
      />
    ),
    cell: (info) => {
      const hasVariable = info.row.original.users.some(
        (u) => u.variable_type && u.variable_type !== 'No',
      );
      return (
        <DTCell
          text={`${formatNumberMoney(info.getValue())}${hasVariable ? ` + ${t('singleCampaign.variablePricing')}` : ''}`}
          icon={<MoneyIcon />}
        />
      );
    },
  }),
  columnHelper.accessor('platforms', {
    size: 150,
    minSize: 150,
    maxSize: 200,
    header: ({ header }) => (
      <DTHeader
        last
        onClick={() => handleChangeSortDirection(header.id)}
        title={t('campaigns.header5')}
        accessor="platforms"
      />
    ),
    cell: (info) => (
      <div className="flex flex-row gap-x-1">
        {info.getValue().map((p) => (
          <SocialNetworkImage
            key={p + Math.random()}
            size="size-[30px]"
            socialNetwork={p}
          />
        ))}
      </div>
    ),
  }),
];

export const CAMPAIGN_TABLE_SUBROWS = {
  influencer: {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (val: string, id: string, image: string) => {
      return (
        <div className="flex flex-row gap-x-4 items-center">
          {image ? (
            <img
              src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${image}`}
              className={`rounded-full size-[50px] object-cover`}
            />
          ) : (
            <div className={`rounded-full bg-influentia-black size-[50px]`}>
              <DefaultInfluencerIcon className="size-[inherit]" />
            </div>
          )}
          <DTBoldCell content={val} id={id} />
        </div>
      );
    },
  },
  status: {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (val: InfluencerCampaignStatusType) => {
      return <StatusText type="influencer" status={val} />;
    },
  },
  price: {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (val: string) => {
      return <DTCell text={val} icon={<MoneyIcon />} />;
    },
  },
  platforms: {
    size: 150,
    minSize: 150,
    maxSize: 200,
    cell: (val: PlatformType[]) => {
      return (
        <div className="flex flex-row flex-end gap-x-1">
          {val.map((p) => (
            <SocialNetworkImage
              key={p + Math.random()}
              size="size-[30px]"
              socialNetwork={p}
            />
          ))}
        </div>
      );
    },
  },
};
