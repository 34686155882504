import React, { useEffect, useState } from 'react';

import { Warning } from '../../common/Warning';

type WarningNotificationsProps = {
  warning: string;
  startDate: number;
  setStoppedNotifications: (val: undefined) => void;
};

const WarningNotifications = ({
  warning,
  startDate,
  setStoppedNotifications,
}: WarningNotificationsProps) => {
  function calculateTimeLeft() {
    const endTime = new Date(Number(startDate)).getTime() + 30 * 60 * 1000; // 30 minutes in milliseconds
    const currentTime = new Date().getTime();
    const difference = endTime - currentTime;

    if (difference <= 0) {
      return { minutes: 0, seconds: 0 }; // Countdown is finished
    }

    const minutes = Math.floor(difference / 1000 / 60);
    const seconds = Math.floor((difference / 1000) % 60);
    return { minutes, seconds };
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [startDate]);

  if (timeLeft.minutes === 0 && timeLeft.seconds === 0) {
    setStoppedNotifications(undefined);
    return <div>Countdown finished!</div>;
  }

  return (
    <Warning
      warning={warning}
      rightText={`${timeLeft.minutes}m ${timeLeft.seconds}s`}
    />
  );
};

export default WarningNotifications;
