import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { Tooltip } from 'react-tooltip';

import { TooltipIcon } from '../../assets';
import { Error } from '../common/Error';
import { GenericDropdown } from './GenericDropdown';

type Props = {
  label?: string;
  placeholder?: string;
  control?: any;
  validation?: any;
  errors?: any;
  icon?: any;
  id?: string;
  max?: number;
  name: string;
  type?: string;
  height?: 'h-[41px]' | 'h-[25px]' | 'h-[33px]';
  onMouseLeave?: () => void;
  currencySelect?: boolean;
  registerName?: string;
  currentCurrency?: any;
  tooltipText?: string;
  options: string[];
  dropdownDisabled?: boolean;
  amountInputDisabled?: boolean;
};

export const VariablePriceInput = ({
  label,
  placeholder,
  control,
  validation,
  errors,
  icon,
  name,
  type = 'default',
  height = 'h-[41px]',
  onMouseLeave,
  registerName = 'currency',
  currentCurrency,
  options,
  tooltipText,
  dropdownDisabled = false,
  amountInputDisabled = false,
}: Props) => {
  const { t } = useTranslation('common');
  const INPUT_CLASSNAME = `${icon ? 'pr-3 pl-9' : 'pl-1 pr-3'} ${amountInputDisabled ? '!bg-light-blue' : ''} ${errors ? 'border border-red-600' : 'border-none'} text-dark-blue block ${height} w-full py-[8px] text-sm font-medium placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0 rounded-e-[4px]`;
  let labelClassname = 'my-2 block text-[14px] font-[600]';
  if (type === 'blue') {
    labelClassname =
      'my-1.5 font-medium text-[10px] text-influentia-light-blue block';
  }
  return (
    <div className="w-full">
      <div className="flex flex-row">
        {label && (
          <label htmlFor={label} className={labelClassname}>
            {label}
          </label>
        )}
        <div
          data-tooltip-id={`tooltip-${label}`}
          data-tooltip-content={tooltipText}
          className={`flex items-center justify-center ml-2 ${tooltipText ? 'cursor-pointer' : 'hidden'}`}
        >
          <TooltipIcon className="text-influentia-light-blue cursor-pointer" />
          <Tooltip
            className="!bg-dark-blue !opacity-100 !text-white font-montserrat !p-1 !text-sm rounded-10 max-w-[300px] text-center z-50"
            noArrow
            id={`tooltip-${label}`}
            place="bottom"
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex flex-row">
          <div className={`flex flex-row`}>
            <div className={`flex flex-row`}>
              <div className="!min-w-[170px] w-full">
                <GenericDropdown
                  options={options.map((val) => ({
                    label: t(`varibale_price_type.${val}`),
                    value: val,
                  }))}
                  isClearable={false}
                  control={control}
                  validation={validation}
                  name={registerName}
                  customBorderRadius={'4px 0px 0px 4px'}
                  isDisabled={dropdownDisabled}
                />
              </div>
              <div className={`bg-white flex items-center`}>
                <div className="bg-[#B5B5B5] w-px h-[70%]"></div>
              </div>
            </div>
            <Controller
              control={control}
              rules={validation}
              name={name}
              render={({ field: { onChange, value, ...rest } }) => (
                <NumericFormat
                  value={value === undefined || value === null ? '' : value}
                  thousandSeparator={'.'}
                  decimalSeparator=","
                  thousandsGroupStyle="thousand"
                  decimalScale={2}
                  fixedDecimalScale
                  suffix={currentCurrency || '€'}
                  onChange={(val: any) =>
                    onChange(
                      val.target.value
                        .replace(/["€"."$"."(".")"]/gi, '')
                        .replace(',', '.') || null,
                    )
                  }
                  onBlur={onMouseLeave}
                  onBlurCapture={() => {}}
                  onChangeCapture={() => {}}
                  onPointerLeave={() => {}}
                  placeholder={placeholder}
                  className={INPUT_CLASSNAME}
                  autoComplete="off"
                  disabled={amountInputDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="mt-1">{errors && <Error error={errors.message} />}</div>
    </div>
  );
};
