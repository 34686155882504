import type { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import type { MultiValue, SingleValue } from 'react-select';
import Select, { components } from 'react-select';

import { DefaultInfluencerIcon, DropdownIcon } from '../../assets';
import { INVOICE_STATUS } from '../../config/constants';

type GenericDropdownProps = {
  control: any;
  placeholder?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  options: { value: string; label: string; profile_picture?: string }[];
  validation?: any;
  name: string;
  label?: string;
  formatLabelOption?: (data: {
    value: string;
    label: string;
    image?: string;
  }) => ReactNode;
  onChangeManual?: (val: any) => void;
  isMulti?: boolean;
  height?: 'h-[41px]' | 'h-[25px]' | 'h-[37px]';
  colored?: boolean;
  hasProfilePicture?: boolean;
  storeFullOption?: boolean; // Nuevo parámetro para almacenar el objeto completo
  id?: string;
  customBorderRadius?: any;
};

const CustomOption = (props: any) => (
  <components.Option {...props}>
    <div className="flex items-center">
      {props.data.profile_picture ? (
        <img
          src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${props.data.profile_picture}`}
          className="size-[20px] mr-2 object-cover rounded-full"
        />
      ) : (
        <DefaultInfluencerIcon className="size-[20px] mr-2" />
      )}
      {props.data.label}
    </div>
  </components.Option>
);

const CustomSingleValue = (props: any) => (
  <components.SingleValue {...props}>
    <div className="flex items-center">
      {props.data.profile_picture ? (
        <img
          src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${props.data.profile_picture}`}
          className="size-[20px] mr-2 object-cover rounded-full"
        />
      ) : (
        <DefaultInfluencerIcon className="size-[20px] mr-2" />
      )}
      {props.data.label}
    </div>
  </components.SingleValue>
);

export const GenericDropdown = ({
  control,
  isDisabled = false,
  isLoading = false,
  isClearable = true,
  isSearchable = false,
  options,
  validation,
  name,
  label,
  formatLabelOption,
  onChangeManual,
  placeholder,
  isMulti = false,
  height = 'h-[41px]',
  colored = false,
  hasProfilePicture = false,
  storeFullOption = false, // Nuevo parámetro para almacenar el objeto completo
  id,
  customBorderRadius,
}: GenericDropdownProps) => {
  const bgColor = (props: any) => {
    if (colored && !isMulti) {
      const isThisOption = props.options[0].value === INVOICE_STATUS[0];
      if (isThisOption) {
        if (
          props.getValue()[0] &&
          props.getValue()[0].value === INVOICE_STATUS[0]
        ) {
          return '!bg-influentia-light-blue';
        }
        if (
          props.getValue()[0] &&
          props.getValue()[0].value === INVOICE_STATUS[1]
        ) {
          return '!bg-green-status';
        }
      }
    }
    return '';
  };

  return (
    <div className="w-full">
      {label && (
        <label htmlFor={label} className="my-2 block text-[14px] font-[600]">
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={validation}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            value={
              // eslint-disable-next-line no-nested-ternary
              isMulti
                ? options.filter((o) =>
                    value?.some((v: any) =>
                      storeFullOption
                        ? v.value === o.value && v.label === o.label
                        : v === o.value,
                    ),
                  )
                : storeFullOption
                  ? options.find(
                      (o) =>
                        o.value === value?.value && o.label === value?.label,
                    )
                  : options.find((o) => o.value === value) || null
            }
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            id={id || label}
            placeholder={placeholder}
            name={name}
            options={options}
            isMulti={isMulti}
            components={{
              DropdownIndicator: ({ ...props }) => (
                <components.ClearIndicator {...props}>
                  {props.children}
                  <DropdownIcon
                    className={`text-black ${props.selectProps.menuIsOpen ? 'rotate-180' : ''}`}
                  />
                </components.ClearIndicator>
              ),
              Option: hasProfilePicture ? CustomOption : components.Option,
              SingleValue: hasProfilePicture
                ? CustomSingleValue
                : components.SingleValue,
            }}
            onChange={(val) => {
              if (onChangeManual) onChangeManual(val);
              onChange(
                // eslint-disable-next-line no-nested-ternary
                isMulti
                  ? (
                      val as MultiValue<{
                        value: string;
                        label: string;
                      }>
                    ).map((v) => (storeFullOption ? v : v.value))
                  : storeFullOption
                    ? (val as SingleValue<{ value: string; label: string }>)
                    : (val as SingleValue<{ value: string; label: string }>)
                        ?.value || '',
              );
            }}
            className={`relative flex w-full items-center text-dark-blue ${error ? '' : ''}  `}
            formatOptionLabel={formatLabelOption}
            classNames={{
              control: (props: any) =>
                `font-montserrat text-sm font-medium ${!isMulti && height} text-dark-blue ${bgColor(props)} ${isDisabled && '!bg-light-blue'} ${
                  error
                    ? '!border-1 !border-red-600 !rounded-[4px]'
                    : '!border-none '
                }`,
            }}
            styles={{
              control: (base) => ({
                ...base,
                width: '100%',
                boxShadow: 'none',
                outline: 'none',
                minHeight: height,
                borderRadius: customBorderRadius || '4px',
              }),
              menuList: (base) => ({
                ...base,
                padding: 0,
                borderRadius: '4px',
                '::-webkit-scrollbar': {
                  width: '6px',
                  height: '0px',
                },
                '::-webkit-scrollbar-track': {
                  background: 'white',
                  borderRadius: '20px',
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#16162C',
                  borderRadius: '20px',
                },
                '::-webkit-scrollbar-thumb:hover': {
                  background: '#16162C',
                  borderRadius: '20px',
                },
              }),
              option: (base, props) => ({
                ...base,
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                background: props.isSelected ? '#213A51' : 'white',
                ':hover': {
                  border: 'none',
                  boxShadow: 'none',
                  background: props.isSelected ? '#213A51' : '#00E7FF',
                },
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: '#fff',
                borderRadius: '4px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                marginTop: '4px',
                minWidth: '200px',
                zIndex: 10,
                right: '0px',
              }),
              placeholder: (styles: any) => ({
                ...styles,
                color: '#5A5A5A',
              }),
              singleValue: (styles: any) => ({
                ...styles,
                color: '#000',
              }),
              valueContainer: (styles: any) => ({
                ...styles,
              }),
              input: (base) => ({
                ...base,
                ring: '0px',
                '--tw-ring-color': 'transparent',
              }),
              indicatorSeparator: (base) => ({
                ...base,
              }),
              multiValue: (base) => ({
                ...base,
                background: '#213A51',
                borderRadius: '4px',
                color: 'white !important',
              }),
              multiValueLabel: (base) => ({
                ...base,
                background: '#213A51',
                borderRadius: '4px',
                color: 'white !important',
              }),
              multiValueRemove: (base) => ({
                ...base,
                borderTopRightRadius: '3px',
                borderBottomRightRadius: '3px',
                ':hover': {
                  background: '#00E7FF',
                },
              }),
            }}
          />
        )}
      />
    </div>
  );
};
