import React from 'react';

import {
  BlogIcon,
  InstagramIcon,
  SubstackIcon,
  TelegramIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../../../../assets';

interface PlatformIconsProps {
  platforms: string[];
  onClickPlatform: (platform: string) => () => void;
}

const PlatformIcons: React.FC<PlatformIconsProps> = ({
  platforms,
  onClickPlatform,
}) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <button type="button" onClick={onClickPlatform('Youtube')}>
        <YoutubeIcon
          className={`${
            platforms.includes('Youtube')
              ? 'text-influentia-light-blue'
              : 'text-dark-blue'
          }`}
        />
      </button>
      <button type="button" onClick={onClickPlatform('Instagram')}>
        <InstagramIcon
          className={`${
            platforms.includes('Instagram')
              ? 'text-influentia-light-blue'
              : 'text-dark-blue'
          }`}
        />
      </button>
      <button type="button" onClick={onClickPlatform('TikTok')}>
        <TiktokIcon
          className={`${
            platforms.includes('TikTok')
              ? 'text-influentia-light-blue'
              : 'text-dark-blue'
          }`}
        />
      </button>
      <button type="button" onClick={onClickPlatform('Twitter')}>
        <TwitterIcon
          className={`${
            platforms.includes('Twitter')
              ? 'text-influentia-light-blue'
              : 'text-dark-blue'
          }`}
        />
      </button>
      <button type="button" onClick={onClickPlatform('Substack')}>
        <SubstackIcon
          className={`${
            platforms.includes('Substack')
              ? 'text-influentia-light-blue'
              : 'text-dark-blue'
          }`}
        />
      </button>
      <button type="button" onClick={onClickPlatform('Blog')}>
        <BlogIcon
          className={`size-[14px] ${
            platforms.includes('Blog')
              ? 'text-influentia-light-blue'
              : 'text-dark-blue'
          }`}
        />
      </button>
      <button type="button" onClick={onClickPlatform('Telegram')}>
        <TelegramIcon
          className={`size-[14px] ${
            platforms.includes('Telegram')
              ? 'text-influentia-light-blue'
              : 'text-dark-blue'
          }`}
        />
      </button>
    </div>
  );
};

export default PlatformIcons;
