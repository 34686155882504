export const ROUTES = {
  LOGOUT: '/logout',
  HOME: '/',
  DASHBOARD: '/dashboard',

  // CAMPAIGNS
  CAMPAIGNS: '/campaigns',
  CAMPAIGNS_RECURRENT: '/campaigns-recurrent',
  SINGLE_CAMPAIGN_GLOBAL: '/campaign/:campaignId/*',
  SINGLE_CAMPAIGN_RECURRENT_GLOBAL: '/campaign-recurrent/:campaignId/*',
  SINGLE_CAMPAIGN_GENERAL: '/campaign/:campaignId/general/:id',
  SINGLE_CAMPAIGN_CONTENT: '/campaign/:campaignId/content/:influencerId',
  SINGLE_CAMPAIGN_DATES: '/campaign/:campaignId/dates/:influencerId',
  SINGLE_CAMPAIGN_METRICS_INFLUENCERS:
    '/campaign/:campaignId/metrics/influencers',
  SINGLE_CAMPAIGN_METRICS_REPORT: '/campaign/:campaignId/metrics/report',
  SINGLE_CAMPAIGN_INVOICES: '/campaign/:campaignId/invoices/:id',
  SINGLE_CAMPAIGN_INVOICES_INFLUENCERS:
    '/campaign/:campaignId/invoices/influencers',
  SINGLE_CAMPAIGN_SETTINGS_INFLUENCER:
    '/campaign/:campaignId/settings-influencer/:influencerId',
  SINGLE_CAMPAIGN_RECURRENT_SETTINGS_INFLUENCER:
    '/campaign-recurrent/:campaignId/settings-influencer/:influencerId',
  SINGLE_CAMPAIGN_SETTINGS: '/campaign/:campaignId/settings',
  SINGLE_CAMPAIGN_RECURRENT_SETTINGS:
    '/campaign-recurrent/:campaignId/settings',
  SINGLE_CAMPAIGN_RESOURCES: '/campaign/:campaignId/resources',
  SINGLE_CAMPAIGN_RESOURCES_RECURRENT:
    '/campaign-recurrent/:campaignId/resources',

  // USERS
  INFLUENTIA_USERS: '/users',
  SINGLE_INFLUENTIA_USER: '/user/:id',

  // INFLUENCERS
  INFLUENCERS: '/influencers',
  SINGLE_INFLUENCER: '/influencer/:id/profile',
  SINGLE_INFLUENCER_CAMPAIGNS: '/influencer/:id/campaigns',

  // BRANDS
  BRANDS: '/brands',
  SINGLE_BRAND: '/brand/:id/profile',
  SINGLE_BRAND_CAMPAIGNS: '/brand/:id/campaigns',

  // INVOICES
  INVOICES: '/invoices',

  // CALENDAR
  CALENDAR: '/calendar',

  // PROFILE
  PROFILE: '/profile/my-account',
  PROFILE_NOTIFICATIONS: '/profile/notifications',
  PROFILE_ORG_AND_ACCESS: '/profile/organization-and-access',

  CONFIRM_CONTENT: '/confirm-content',
};

type ReplaceIdsProps = {
  params: { [key: string]: string }[];
  url: string;
};

export const replaceIds = ({ params, url }: ReplaceIdsProps) => {
  let newUrl = url;
  // eslint-disable-next-line no-restricted-syntax
  for (const p of params) {
    newUrl = newUrl.replace(`:${Object.keys(p)[0]}`, p[Object.keys(p)[0]]);
  }
  return newUrl.replace('/*', '');
};
