import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { replaceIds, ROUTES } from '../../config/routes/config';
import { usePermissions } from '../../hooks/usePermissions';
import { userAtom } from '../../states/atoms';
import type { InfluencerCampaignStatusType } from '../../types';
import { BrandImage } from '../images/BrandImage';
import { UsersImages } from '../images/UserImages';

type SingleCampaignTabsProps = {
  campaignId: string;
  idState: string;
  influencerId: string;
  influencers: {
    id: string;
    profile_picture: string;
    name: string;
    status: InfluencerCampaignStatusType;
  }[];
  brandName: string;
  brandProfilePicture?: string;
  brandId: string;
};

export type TabProps = {
  to: string;
  text: string;
  id?: string;
  matchUrl?: string;
};

export const Tab = ({ to, text, id, matchUrl }: TabProps) => {
  const match = useMatch(matchUrl || '');
  return (
    <NavLink
      id={id}
      className={({ isActive }) =>
        `font-semibold flex flex-none text-sm rounded-[5px] py-2 px-5 ${isActive || match ? 'bg-influentia-light-blue text-dark-blue' : 'bg-dark-blue text-white hover:bg-influentia-light-blue hover:text-dark-blue'}`
      }
      end
      to={to}
      defaultValue={'1'}
    >
      {text}
    </NavLink>
  );
};

export const SingleCampaignTabs = ({
  campaignId,
  idState,
  influencerId,
  influencers,
  brandName,
  brandProfilePicture,
  brandId,
}: SingleCampaignTabsProps) => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { isBrand, isInfluencer, isInfluentia } = usePermissions();
  const user = useRecoilValue(userAtom);

  const hasInfluecersTabs = useMemo(
    () => /^\/campaign\/.*\/settings$/.test(location.pathname),
    [location],
  );

  const isMetricsTab = useMemo(
    () => /^\/campaign\/.*\/metrics\/.*$/.test(location.pathname),
    [location],
  );

  const isInvoicesTab = useMemo(
    () => /^\/campaign\/.*\/invoices\/.*$/.test(location.pathname),
    [location],
  );

  const isGeneralTab = useMemo(
    () => /^\/campaign\/.*\/general\/.*$/.test(location.pathname),
    [location],
  );

  const isResourcesTab = useMemo(
    () => /^\/campaign\/.*\/resources$/.test(location.pathname),
    [location],
  );

  const PARAMS: { [key: string]: string }[] = [
    { campaignId },
    { influencerId },
    { id: idState },
  ];

  const getUrl = (url: string, id?: string) => {
    return replaceIds({
      url,
      params: id ? [...PARAMS, { id }] : [...PARAMS, { id: influencerId }],
    });
  };

  const getUrlInfluencer = (url: string) => {
    return replaceIds({
      url,
      params: [{ id: influencerId }, ...PARAMS],
    });
  };

  const getUrlBrandInvoice = () => {
    return replaceIds({
      url: ROUTES.SINGLE_CAMPAIGN_INVOICES,
      params: [{ campaignId }, { id: brandId }],
    });
  };

  const getUrlBrandGeneral = () => {
    return replaceIds({
      url: ROUTES.SINGLE_CAMPAIGN_GENERAL,
      params: [{ campaignId }, { id: 'campaign' }],
    });
  };

  const getInfluencerTabUrl = (id: string) => {
    return `${location.pathname.split('/').slice(0, -1).join('/')}/${id}`;
  };

  const getInvoicesPath = () => {
    if (isInvoicesTab) return location.pathname;
    if (isBrand()) return getUrlBrandInvoice();
    if (isInfluencer())
      return getUrl(ROUTES.SINGLE_CAMPAIGN_INVOICES_INFLUENCERS);
    return getUrl(ROUTES.SINGLE_CAMPAIGN_INVOICES_INFLUENCERS);
  };

  const getMetricsPath = () => {
    if (isMetricsTab) return location.pathname;
    if (isBrand()) return getUrl(ROUTES.SINGLE_CAMPAIGN_METRICS_REPORT);
    if (isInfluencer())
      return getUrl(ROUTES.SINGLE_CAMPAIGN_METRICS_INFLUENCERS);
    return getUrl(ROUTES.SINGLE_CAMPAIGN_METRICS_INFLUENCERS);
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div
        id="singleCampaignTabs"
        className="flex flex-row gap-x-2 overflow-x-auto customScroll pb-1"
      >
        <Tab
          id="generalTab"
          to={getUrlInfluencer(ROUTES.SINGLE_CAMPAIGN_GENERAL)}
          matchUrl={ROUTES.SINGLE_CAMPAIGN_GENERAL}
          text={t('singleCampaign.tabs.general')}
        />
        <Tab
          id="contentTab"
          to={getUrlInfluencer(ROUTES.SINGLE_CAMPAIGN_CONTENT)}
          matchUrl={ROUTES.SINGLE_CAMPAIGN_CONTENT}
          text={t('singleCampaign.tabs.content')}
        />
        <Tab
          id="resourcesTab"
          to={getUrl(ROUTES.SINGLE_CAMPAIGN_RESOURCES)}
          matchUrl={ROUTES.SINGLE_CAMPAIGN_RESOURCES}
          text={t('singleCampaign.tabs.resources')}
        />
        <Tab
          id="datesTab"
          to={getUrlInfluencer(ROUTES.SINGLE_CAMPAIGN_DATES)}
          matchUrl={ROUTES.SINGLE_CAMPAIGN_DATES}
          text={t('singleCampaign.tabs.dates')}
        />
        <Tab
          id="metricsTab"
          to={getMetricsPath()}
          text={t('singleCampaign.tabs.metrics')}
          matchUrl={ROUTES.SINGLE_CAMPAIGN_METRICS_INFLUENCERS}
        />
        <Tab
          id="invoicestab"
          to={getInvoicesPath()}
          matchUrl={ROUTES.SINGLE_CAMPAIGN_INVOICES}
          text={t('singleCampaign.tabs.invoices')}
        />
        {isInfluentia() && (
          <>
            <Tab
              id="settingsInfluencerTab"
              to={getUrlInfluencer(ROUTES.SINGLE_CAMPAIGN_SETTINGS_INFLUENCER)}
              matchUrl={ROUTES.SINGLE_CAMPAIGN_SETTINGS_INFLUENCER}
              text={t('singleCampaign.tabs.settingsInfluencer')}
            />
            <Tab
              id="settingsTab"
              to={getUrl(ROUTES.SINGLE_CAMPAIGN_SETTINGS)}
              matchUrl={ROUTES.SINGLE_CAMPAIGN_SETTINGS}
              text={t('singleCampaign.tabs.settings')}
            />
          </>
        )}
      </div>
      {!isInfluencer() &&
        !hasInfluecersTabs &&
        !isInvoicesTab &&
        !isMetricsTab &&
        !isResourcesTab && (
          <div
            id="influencersTabs"
            className={`flex flex-row overflow-x-auto customScroll pb-1 gap-x-2`}
          >
            {isGeneralTab && !isInfluencer() && (
              <div
                id="invoicesTab"
                className={`flex flex-row gap-x-2 flex-none`}
              >
                <NavLink
                  id="brandTab"
                  className={({ isActive }) =>
                    `${isActive ? '!bg-influentia-light-blue' : 'bg-white'} flex-none text-sm items-center font-semibold text-influentia-black flex flex-row gap-x-2 rounded-full px-4 py-1`
                  }
                  to={getUrlBrandGeneral()}
                >
                  <BrandImage image={brandProfilePicture} size="size-[24px]" />
                  {t('global.campaign')}
                </NavLink>
              </div>
            )}
            {influencers.map((i) => (
              <NavLink
                key={i.id + campaignId}
                className={({ isActive }) =>
                  `${isActive ? '!bg-influentia-light-blue' : 'bg-white'} flex-none text-sm items-center font-semibold text-influentia-black flex flex-row gap-x-2 rounded-full px-4 py-1`
                }
                to={getInfluencerTabUrl(i.id)}
              >
                <UsersImages
                  userCount={1}
                  userImages={[i.profile_picture]}
                  size="size-[24px]"
                />
                {i.name}
                {' - '}
                {t(`influencerStatus.${i.status}`)}
              </NavLink>
            ))}
          </div>
        )}
      {isInvoicesTab && isInfluentia() && (
        <div id="invoicesTab" className={`flex flex-row gap-x-2`}>
          <NavLink
            id="invoicesInfluencersTab"
            className={({ isActive }) =>
              `${isActive ? '!bg-influentia-light-blue' : 'bg-white'} h-[32px] text-sm items-center font-semibold text-influentia-black flex flex-row gap-x-2 rounded-full px-4 py-1`
            }
            to={getUrl(ROUTES.SINGLE_CAMPAIGN_INVOICES_INFLUENCERS)}
          >
            {t('singleCampaign.tabs.invoicesInfluencers')}
          </NavLink>
          <NavLink
            id="brandTab"
            className={({ isActive }) =>
              `${isActive ? '!bg-influentia-light-blue' : 'bg-white'} flex-none text-sm items-center font-semibold text-influentia-black flex flex-row gap-x-2 rounded-full px-4 py-1`
            }
            to={getUrlBrandInvoice()}
          >
            <BrandImage image={brandProfilePicture} size="size-[24px]" />
            {brandName}
          </NavLink>
        </div>
      )}
      {isMetricsTab && isInfluentia() && (
        <div id="metricsTab" className={`flex flex-row gap-x-2`}>
          <NavLink
            id="metricsInfluencersTab"
            className={({ isActive }) =>
              `${isActive ? '!bg-influentia-light-blue' : 'bg-white'} h-[32px] text-sm items-center font-semibold text-influentia-black flex flex-row gap-x-2 rounded-full px-4 py-1`
            }
            to={getUrl(ROUTES.SINGLE_CAMPAIGN_METRICS_INFLUENCERS)}
          >
            {t('singleCampaign.tabs.metricsInfluencers')}
          </NavLink>
          <NavLink
            id="metricsReportTab"
            className={({ isActive }) =>
              `${isActive ? '!bg-influentia-light-blue' : 'bg-white'} h-[32px] text-sm items-center font-semibold text-influentia-black flex flex-row gap-x-2 rounded-full px-4 py-1`
            }
            to={getUrl(ROUTES.SINGLE_CAMPAIGN_METRICS_REPORT)}
          >
            {t('singleCampaign.tabs.metricsReport')}
          </NavLink>
        </div>
      )}
    </div>
  );
};
