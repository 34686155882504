import type { ReactNode } from 'react';

import Loader from '../loaders/LoaderSpinner';

type ButtonProps = {
  text: string;
  onClick?: () => void;
  style:
    | 'black'
    | 'light-blue'
    | 'black-no-bg'
    | 'light-blue-underline'
    | 'dark-blue'
    | 'white';
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  customClassnames?: string;
  type?: 'button' | 'submit';
  loading?: boolean;
  disabled?: boolean;
  id?: string;
};

export const Button = ({
  text,
  onClick,
  style,
  iconLeft,
  iconRight,
  customClassnames,
  type,
  loading,
  disabled,
  id,
}: ButtonProps) => {
  const getClassnames = () => {
    switch (style) {
      case 'light-blue-underline':
        return 'bg-transparent underline text-influentia-light-blue text-sm font-normal hover:text-influentia-black';
      case 'light-blue':
        return 'bg-influentia-light-blue text-dark-blue text-sm font-normal hover:bg-dark-blue hover:text-white';
      case 'black-no-bg':
        return 'bg-transparent text-influentia-black text-sm font-normal hover:text-influentia-light-blue';
      case 'dark-blue':
        return 'bg-dark-blue text-white text-sm font-bold hover:bg-influentia-light-blue hover:text-dark-blue';
      case 'white':
        return 'bg-white text-dark-blue text-sm hover:bg-influentia-light-blue hover:text-dark-blue';
      case 'black':
      default:
        return 'bg-influentia-black text-white text-sm font-normal hover:bg-influentia-light-blue hover:text-influentia-black';
    }
  };
  return (
    <button
      id={id}
      className={`${getClassnames()} ${(iconLeft || iconRight) && !text ? 'px-4' : 'px-3'} ${customClassnames} flex flex-row items-center gap-x-1.5 py-1.5 rounded-[4px] ${disabled && 'opacity-80 cursor-not-allowed'}`}
      onClick={onClick}
      disabled={disabled}
      type={type || 'button'}
    >
      {loading ? (
        <Loader size="size-[20px]" />
      ) : (
        <>
          {iconLeft}
          {text}
          {iconRight}
        </>
      )}
    </button>
  );
};
