import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SingleValue } from 'react-select';
import Select, { components } from 'react-select';

import { DropdownIcon } from '../../assets';
import { INVOICE_STATUS } from '../../config/constants';
import { Confirm } from '../../context/confirm/confirm';

type GenericDropdownProps = {
  control: any;
  placeholder?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  options: { value: string; label: string }[];
  validation?: any;
  name: string;
  label?: string;
  formatLabelOption?: (data: {
    value: string;
    label: string;
    image?: string;
  }) => ReactNode;
  onChangeManual?: (val: any) => void;
  height?: 'h-[41px]' | 'h-[25px]' | 'h-[37px]' | string;
  colored?: boolean;
  id?: string;
  joinComponent?: boolean;
  confirmPopup?: boolean; // Nuevo parámetro
};

export const MoneyCurrencyDropdown = ({
  control,
  isDisabled = false,
  isLoading = false,
  isClearable = true,
  isSearchable = false,
  options,
  validation,
  name,
  label,
  formatLabelOption,
  onChangeManual,
  placeholder,
  height = 'h-[41px]',
  colored = false,
  joinComponent = true,
  confirmPopup = false, // Nuevo parámetro con valor por defecto false
  id,
}: GenericDropdownProps) => {
  const [open, setOpen] = useState(false);

  const bgColor = (props: any) => {
    if (colored) {
      const isThisOption = props.options[0].value === INVOICE_STATUS[0];
      if (isThisOption) {
        if (
          props.getValue()[0] &&
          props.getValue()[0].value === INVOICE_STATUS[0]
        ) {
          return '!bg-influentia-light-blue';
        }
        if (
          props.getValue()[0] &&
          props.getValue()[0].value === INVOICE_STATUS[1]
        ) {
          return '!bg-green-status';
        }
      }
    }
    return '';
  };
  const { t } = useTranslation('common');

  const [pendingValue, setPendingValue] = useState<SingleValue<{
    value: string;
    label: string;
  }> | null>(null);
  const [confirmedValue, setConfirmedValue] = useState<string | null>(null);

  const handleConfirm = () => {
    if (pendingValue) {
      if (onChangeManual) onChangeManual(pendingValue);
      setConfirmedValue(pendingValue.value);
    }
  };

  return (
    <>
      {confirmPopup && (
        <Confirm
          open={open}
          onConfirm={handleConfirm}
          setOpen={setOpen}
          type="info"
          title={t('singleCampaign.changeCurrencyTitle')}
          subtitle={t('singleCampaign.changeCurrencySubTitle')}
          confirmText={t('singleCampaign.changeCurrencyConfirm')}
          cancelText={t('global.cancel')}
        />
      )}
      <div
        className={`${!label ? 'min-w-[55px] max-w-[70px] w-full' : 'w-full'} ${height}`}
      >
        {label && (
          <label htmlFor={label} className="my-2 block text-[14px] font-[600]">
            {label}
          </label>
        )}
        <Controller
          name={name}
          control={control}
          rules={validation}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            useEffect(() => {
              if (confirmedValue !== null) {
                onChange(confirmedValue); // Aplicamos el valor confirmado
                setConfirmedValue(null); // Limpiamos el estado después de aplicarlo
              }
            }, [confirmedValue]);

            return (
              <Select
                value={options.find((o) => o.value === value)}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                id={id || label}
                placeholder={placeholder}
                name={name}
                options={options}
                components={{
                  DropdownIndicator: ({ ...props }) => (
                    <components.ClearIndicator {...props}>
                      <DropdownIcon
                        className={`text-black ${props.selectProps.menuIsOpen ? 'rotate-180' : ''}`}
                      />
                    </components.ClearIndicator>
                  ),
                  Option: components.Option,
                  SingleValue: components.SingleValue,
                }}
                onChange={(val: any) => {
                  if (confirmPopup && val?.value !== value) {
                    setPendingValue(
                      val as SingleValue<{ value: string; label: string }>,
                    ); // Guardamos el valor pendiente
                    setOpen(true); // Abrimos el popup de confirmación
                  } else {
                    if (onChangeManual) onChangeManual(val);
                    onChange(
                      (val as SingleValue<{ value: string; label: string }>)
                        ?.value || '',
                    );
                  }
                }}
                className={`relative flex items-center text-dark-blue ${error ? '' : ''}  `}
                formatOptionLabel={formatLabelOption}
                classNames={{
                  control: (props: any) =>
                    `font-montserrat !w-[60px] text-sm font-medium ${height} text-dark-blue  ${
                      joinComponent ? '!rounded-none !rounded-s-[4px]' : ''
                    } ${bgColor(props)} ${isDisabled && '!bg-light-blue'} ${
                      error
                        ? '!border-1 !border-red-600'
                        : '!border-none rounded-[4px]'
                    }`,
                  indicatorsContainer: (props: any) =>
                    `${height === 'h-[25px]' ? '!p-0 !h-full' : ''}`,
                  valueContainer: (props: any) => `!pr-0`,
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    boxShadow: 'none',
                    outline: 'none',
                    minHeight: height,
                  }),
                  menuList: (base) => ({
                    ...base,
                    padding: 0,

                    borderRadius: '4px',
                    '::-webkit-scrollbar': {
                      width: '6px',
                      height: '0px',
                    },
                    '::-webkit-scrollbar-track': {
                      background: 'white',
                      borderRadius: '20px',
                    },
                    '::-webkit-scrollbar-thumb': {
                      background: '#16162C',
                      borderRadius: '20px',
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                      background: '#16162C',
                      borderRadius: '20px',
                    },
                  }),
                  option: (base, props) => ({
                    ...base,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',

                    background: props.isSelected ? '#213A51' : 'white',
                    ':hover': {
                      border: 'none',
                      boxShadow: 'none',
                      background: props.isSelected ? '#213A51' : '#00E7FF',
                    },
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: '#fff',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    marginTop: '4px',
                    zIndex: 10,
                    right: '0px',
                  }),
                  placeholder: (styles: any) => ({
                    ...styles,
                    color: '#5A5A5A',
                  }),
                  singleValue: (styles: any) => ({
                    ...styles,
                    color: '#000',
                  }),
                  valueContainer: (styles: any) => ({
                    ...styles,
                  }),
                  input: (base) => ({
                    ...base,
                    ring: '0px',
                    '--tw-ring-color': 'transparent',
                  }),
                  indicatorSeparator: (base) => ({
                    ...base,
                    display: 'none',
                  }),
                  multiValue: (base) => ({
                    ...base,
                    background: '#213A51',
                    color: 'white !important',
                  }),
                  multiValueLabel: (base) => ({
                    ...base,
                    background: '#213A51',
                    color: 'white !important',
                  }),
                  multiValueRemove: (base) => ({
                    ...base,
                    borderTopRightRadius: '3px',
                    borderBottomRightRadius: '3px',
                    ':hover': {
                      background: '#00E7FF',
                    },
                  }),
                }}
              />
            );
          }}
        />
      </div>
    </>
  );
};
