import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import CheckAuth from '../../components/auth/CheckAuth';
import RequireAuth from '../../components/auth/RequireAuth';
import { ContentConfirm } from '../../components/campaigns/content/ContentConfirm';
import { SingleCampaignContentTab } from '../../components/campaigns/SingleTabs/ContentTab';
import { SingleCampaignDatesTab } from '../../components/campaigns/SingleTabs/DatesTab';
import { SingleCampaignGeneralTab } from '../../components/campaigns/SingleTabs/GeneralTab';
import { SingleCampaignInvoicesTab } from '../../components/campaigns/SingleTabs/InvoicesTab';
import { SingleCampaignMetricsInfluencersTab } from '../../components/campaigns/SingleTabs/MetricsInfluencersTab';
import { SingleCampaignMetricsTab } from '../../components/campaigns/SingleTabs/MetricsReportTab';
import { SingleCampaignResourcesTab } from '../../components/campaigns/SingleTabs/ResourcesTab';
import { SingleCampaignRecurrentResourcesTab } from '../../components/campaigns/SingleTabs/ResourcesTabRecurrent';
import { SingleCampaignSettingsInfluencerTab } from '../../components/campaigns/SingleTabs/SettingsInfluencerTab';
import { SingleCampaignRecurrentSettingsInfluencerTab } from '../../components/campaigns/SingleTabs/SettingsInfluencerTabRecurrent';
import { SingleCampaignSettingsTab } from '../../components/campaigns/SingleTabs/SettingsTab';
import { SingleCampaignSettingsRecurrentTab } from '../../components/campaigns/SingleTabs/SettingsTabRecurrent';
import { NotFound } from '../../components/notfound/notFound';
import { usePermissions } from '../../hooks/usePermissions';
import DashboardLayout from '../../layouts/DashboardLayout';
import Brands from '../../views/Brands';
import Calendar from '../../views/Calendar';
import Campaigns from '../../views/Campaigns';
import CampaignsRecurrent from '../../views/CampaignsRecurrent';
import Dashboard from '../../views/Dashbord';
import Influencers from '../../views/Influencers';
import InfluentiaUsers from '../../views/InfluentiaUsers';
import Invoices from '../../views/Invoices';
import Profile from '../../views/Profile';
import ProfileBrandAccess from '../../views/ProfileBrandAccess';
import ProfileNotifications from '../../views/ProfileNotifications';
import { SingleBrand } from '../../views/SingleBrand';
import { SingleBrandCampaigns } from '../../views/SingleBrandCampaigns';
import SingleCampaign from '../../views/SingleCampaign';
import SingleCampaignRecurrent from '../../views/SingleCampaignRecurrent';
import { SingleInfluencer } from '../../views/SingleInfluencer';
import { SingleInfluencerCampaigns } from '../../views/SingleInfluencerCampaigns';
import { SingleInfluentia } from '../../views/SingleInfluentiaUser';
import { ROUTES } from './config';

const AppRouter = () => {
  const { isInfluentia, isBrand, isInfluencer, userLoaded } = usePermissions();
  const getSingleCampaignTabRoutes = (url: any) => {
    return url.replace('/campaign/:campaignId/', '');
  };
  const getSingleCampaignRecurrentTabRoutes = (url: any) => {
    return url.replace('/campaign-recurrent/:campaignId/', '');
  };
  // const location = useLocation();
  // useEffect(() => {
  //   // Reset States according to location
  // }, [location]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<CheckAuth />} />
        <Route
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.CAMPAIGNS} element={<Campaigns />} />
          <Route path={ROUTES.CALENDAR} element={<Calendar />} />
          <Route path={ROUTES.INVOICES} element={<Invoices />} />
          <Route path={ROUTES.PROFILE} element={<Profile />} />
          <Route
            path={ROUTES.PROFILE_NOTIFICATIONS}
            element={<ProfileNotifications />}
          />
          <Route
            path={ROUTES.PROFILE_ORG_AND_ACCESS}
            element={<ProfileBrandAccess />}
          />
          <Route
            path={ROUTES.SINGLE_CAMPAIGN_GLOBAL}
            element={<SingleCampaign />}
          >
            <Route
              path={getSingleCampaignTabRoutes(ROUTES.SINGLE_CAMPAIGN_GENERAL)}
              element={<SingleCampaignGeneralTab />}
            />
            <Route
              path={getSingleCampaignTabRoutes(ROUTES.SINGLE_CAMPAIGN_CONTENT)}
              element={<SingleCampaignContentTab />}
            />
            <Route
              path={getSingleCampaignTabRoutes(
                ROUTES.SINGLE_CAMPAIGN_RESOURCES,
              )}
              element={<SingleCampaignResourcesTab />}
            />
            <Route
              path={getSingleCampaignTabRoutes(ROUTES.SINGLE_CAMPAIGN_DATES)}
              element={<SingleCampaignDatesTab />}
            />
            {!isBrand() && (
              <Route
                path={getSingleCampaignTabRoutes(
                  ROUTES.SINGLE_CAMPAIGN_METRICS_INFLUENCERS,
                )}
                element={<SingleCampaignMetricsInfluencersTab />}
              />
            )}
            {!isInfluencer() && (
              <Route
                path={getSingleCampaignTabRoutes(
                  ROUTES.SINGLE_CAMPAIGN_METRICS_REPORT,
                )}
                element={<SingleCampaignMetricsTab />}
              />
            )}
            <Route
              path={getSingleCampaignTabRoutes(ROUTES.SINGLE_CAMPAIGN_INVOICES)}
              element={<SingleCampaignInvoicesTab />}
            />
            <Route
              path={getSingleCampaignTabRoutes(
                ROUTES.SINGLE_CAMPAIGN_SETTINGS_INFLUENCER,
              )}
              element={<SingleCampaignSettingsInfluencerTab />}
            />
            <Route
              path={getSingleCampaignTabRoutes(ROUTES.SINGLE_CAMPAIGN_SETTINGS)}
              element={<SingleCampaignSettingsTab />}
            />
          </Route>

          {((userLoaded && isInfluentia()) || !userLoaded) && (
            <>
              <Route
                path={ROUTES.SINGLE_CAMPAIGN_RECURRENT_GLOBAL}
                element={<SingleCampaignRecurrent />}
              >
                <Route
                  path={getSingleCampaignRecurrentTabRoutes(
                    ROUTES.SINGLE_CAMPAIGN_RESOURCES_RECURRENT,
                  )}
                  element={<SingleCampaignRecurrentResourcesTab />}
                />
                <Route
                  path={getSingleCampaignRecurrentTabRoutes(
                    ROUTES.SINGLE_CAMPAIGN_RECURRENT_SETTINGS_INFLUENCER,
                  )}
                  element={<SingleCampaignRecurrentSettingsInfluencerTab />}
                />
                <Route
                  path={getSingleCampaignRecurrentTabRoutes(
                    ROUTES.SINGLE_CAMPAIGN_RECURRENT_SETTINGS,
                  )}
                  element={<SingleCampaignSettingsRecurrentTab />}
                />
              </Route>
              <Route path={ROUTES.BRANDS} element={<Brands />} />
              <Route path={ROUTES.INFLUENCERS} element={<Influencers />} />
              <Route
                path={ROUTES.CAMPAIGNS_RECURRENT}
                element={<CampaignsRecurrent />}
              />

              <Route
                path={ROUTES.SINGLE_INFLUENCER}
                element={<SingleInfluencer />}
              />
              <Route
                path={ROUTES.SINGLE_INFLUENCER_CAMPAIGNS}
                element={<SingleInfluencerCampaigns />}
              />
              <Route
                path={ROUTES.SINGLE_INFLUENTIA_USER}
                element={<SingleInfluentia />}
              />
              <Route path={ROUTES.SINGLE_BRAND} element={<SingleBrand />} />
              <Route
                path={ROUTES.SINGLE_BRAND_CAMPAIGNS}
                element={<SingleBrandCampaigns />}
              />
              <Route
                path={ROUTES.INFLUENTIA_USERS}
                element={<InfluentiaUsers />}
              />
            </>
          )}
          <Route path="*" element={<NotFound />} />
          <Route path={ROUTES.CONFIRM_CONTENT} element={<ContentConfirm />} />
        </Route>
      </>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
