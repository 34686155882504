import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { CheckBoxReferralIcon } from '../../../assets';
import { RefetchLateral } from '../../../context/refetchLateral/refetchProvider';
import { UPDATE_CAMPAIGN } from '../../../graphql/campaign/mutation';
import { GET_SETTINGS } from '../../../graphql/campaign/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePrompt } from '../../../hooks/usePrompt';
import { Button } from '../../common/Button';
import { GenericDropdown } from '../../inputs/GenericDropdown';
import { MoneyInput } from '../../inputs/MoneyInput';
import { TextInput } from '../../inputs/TextInput';
import type { File } from '../content/File';
import DescriptionPlatforms from '../settings/Description';
import DisclaimerPlatforms from '../settings/Disclaimer';
import SelectInfluencer from '../settings/SelectInfluencer';

type Settings = {
  campaignSettings: {
    data: {
      id: string;
      name: string;
      money: number;
      currency: string;
      brand: {
        id: string;
        name: string;
      };
      allBrands: {
        id: string;
        name: string;
      }[];
      briefing: {
        id: string;
        name: string;
        url: string;
      };
      allInfluencers: {
        id: string;
        name: string;
        File: {
          id: string;
          name: string;
          url: string;
        };
      }[];
      influencers: {
        id: string;
        name: string;
        profile_picture: string;
      }[];
      disclaimers: {
        id: string;
        platform: string;
        disclaimer: string;
      }[];
      descriptions: {
        id: string;
        description: string;
        platform: string;
      }[];
    };
  };
};

export const SingleCampaignSettingsTab = () => {
  const [campaignId]: [string] = useOutletContext();
  const { t } = useTranslation('common');
  const [generalInfo, setGeneralInfo] =
    useState<Settings['campaignSettings']['data']>();
  const [influencers, setInfluencers] = useState<
    Settings['campaignSettings']['data']['influencers']
  >([]);
  const [disclaimers, setDisclaimers] = useState<
    Settings['campaignSettings']['data']['disclaimers']
  >([]);
  const [descriptions, setDescriptions] = useState<
    Settings['campaignSettings']['data']['descriptions']
  >([]);

  const [allInfluencers, setAllInfluencers] = useState<
    Settings['campaignSettings']['data']['allInfluencers']
  >([]);
  const { refetchLateral, refetchTabs } = useContext(RefetchLateral);

  const {
    control,
    setValue,
    register,
    watch,
    reset,
    formState: { dirtyFields },
  } = useForm();
  const [dirty, setDirty] = useState(false);
  const { refetch } = useQuery<Settings>(GET_SETTINGS, {
    variables: {
      campaignId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const info = data.campaignSettings.data;
      setGeneralInfo(info);
      setValue('total_value', info.money, { shouldDirty: false });
      setValue('client', info.brand.id, { shouldDirty: false });
      setValue('currency', info.currency, { shouldDirty: false });
      // include all the influencers excepts already in influencers
      setAllInfluencers(info.allInfluencers);
      setInfluencers(info.influencers);
      setDisclaimers(info.disclaimers);
      setDescriptions(info.descriptions);
      setDirty(false);
    },
  });
  useEffect(() => {
    influencers.forEach((influencer, index) => {
      setValue(`influencer-${index}`, influencer.id, { shouldDirty: false });
    });
  }, [influencers]);

  const deleteInfluencer = (id: string) => {
    setInfluencers((prevInfluencers) => {
      return prevInfluencers.filter((influencer) => influencer.id !== id);
    });
    setDirty(true);
  };
  const addInfluencer = () => {
    setInfluencers((prevInfluencers) => {
      return [
        ...prevInfluencers,
        {
          id: Math.floor(Math.random() * 1000).toString(),
          name: '',
          profile_picture: '',
        },
      ];
    });
    setDirty(true);
  };

  const onSelectInfluencer = (val: any, index: number) => {
    const influencer = allInfluencers.find(
      (findInflu: any) => findInflu.id === val.value,
    );

    // Check if influencer is already in the list
    const isAlreadyInList = influencers.find(
      (influ: any) => influ.id === val.value,
    );
    if (isAlreadyInList) {
      return;
    }

    if (influencer) {
      setInfluencers((prevInfluencers) => {
        const newInfluencers = [...prevInfluencers];
        newInfluencers[index] = {
          id: influencer.id,
          name: influencer.name,
          profile_picture: influencer.File ? influencer.File.url : '',
        };
        return newInfluencers;
      });
      setDirty(true);
    }
  };

  const addDescription = () => {
    setDescriptions((prevDescriptions) => {
      return [
        ...prevDescriptions,
        {
          id: Math.floor(Math.random() * 1000).toString(),
          description: '',
          platform: '',
        },
      ];
    });
    setDirty(true);
  };
  const addDisclaimer = () => {
    setDisclaimers((prevDisclaimers) => {
      return [
        ...prevDisclaimers,
        {
          id: Math.floor(Math.random() * 1000).toString(),
          disclaimer: '',
          platform: '',
        },
      ];
    });
    setDirty(true);
  };
  const deleteDescription = (id: string) => {
    setDescriptions((prevDescriptions) => {
      return prevDescriptions.filter((description) => description.id !== id);
    });
    setDirty(true);
  };
  const deleteDisclaimer = (id: string) => {
    setDisclaimers((prevDisclaimers) => {
      return prevDisclaimers.filter((disclaimer) => disclaimer.id !== id);
    });
    setDirty(true);
  };
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const [updateCampaign, { loading }] = useMutation(UPDATE_CAMPAIGN);

  const onCompleted = () => {
    notifySaveSuccess();
    refetch();
    setDirty(false);
    reset({}, { keepValues: true });
    refetchLateral();
    refetchTabs();
  };

  const updateCampaignDB = (file?: File) => {
    const headers: any = {
      'x-apollo-operation-name': 'setCampaignSettings',
    };

    updateCampaign({
      variables: {
        campaignId,
        name: watch('name'),
        descriptions: descriptions.map((description) => {
          return {
            description: description.description,
            platform: description.platform,
          };
        }),
        disclaimers: disclaimers.map((disclaimer) => {
          return {
            disclaimer: disclaimer.disclaimer,
            platform: disclaimer.platform,
          };
        }),
        money: Number(watch('total_value')),
        currency: watch('currency'),
        brandId: watch('client'),
        influencers: influencers.map((influencer) => influencer.id),
      },
      context: {
        headers,
      },
      onCompleted: () => {
        onCompleted();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const updateCampaignDBCurrency = () => {
    const headers: any = {
      'x-apollo-operation-name': 'setCampaignSettings',
    };

    updateCampaign({
      variables: {
        campaignId,
        currency: watch('currency'),
      },
      context: {
        headers,
      },
      onCompleted: () => {
        onCompleted();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };
  const handleSave = () => {
    updateCampaignDB();
  };

  useEffect(() => {
    if (watch('currency') !== generalInfo?.currency) updateCampaignDBCurrency();
  }, [watch('currency')]);

  usePrompt({
    isDirty: Object.keys(dirtyFields).length > 0 || dirty,
  });

  return (
    <div>
      <div className="flex lg:flex-row flex-col gap-5">
        <TextInput
          type="text"
          label={t('singleCampaign.campaignName')}
          selectedValue={generalInfo?.name}
          onSelectChange={(value) =>
            setValue('name', value, { shouldDirty: true })
          }
        />
        {generalInfo?.allBrands && (
          <GenericDropdown
            label={t('singleCampaign.client')}
            name="client"
            control={control}
            options={generalInfo.allBrands.map((brand) => {
              return { value: brand.id, label: brand.name };
            })}
          />
        )}
      </div>
      <div className="flex lg:flex-row flex-col mt-5 gap-5">
        <div className="w-full">
          <MoneyInput
            confirmPopup={true}
            label={t('singleCampaign.totalAmount')}
            name="total_value"
            currentCurrency={watch('currency')}
            control={control}
            currencySelect={true}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1 mt-5">
        <label className="my-2 block text-[14px] font-[600]">
          {t('singleCampaign.addinfluencers')}
        </label>
        {influencers &&
          influencers.map((influencerItem, index: number) => {
            return (
              <SelectInfluencer
                key={influencerItem.id}
                index={index}
                control={control}
                onSelectInfluencer={onSelectInfluencer}
                allInfluencers={allInfluencers}
                selectedInfluencers={influencers}
                deleteInfluencer={() => deleteInfluencer(influencerItem.id)}
              />
            );
          })}
        <button
          className="w-full flex items-center justify-center"
          onClick={() => addInfluencer()}
        >
          <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
            <CheckBoxReferralIcon />
            <span className="text-[10px] text-influentia-light-blue font-medium">
              {t('singleCampaign.addInfluencer')}
            </span>
          </div>
        </button>
      </div>
      <div className="flex flex-col gap-1 mt-5">
        <label className="my-2 block text-[14px] font-[600]">
          {t('singleCampaign.disclaimer')}
        </label>
        {disclaimers &&
          disclaimers.map((disclaimer) => (
            <DisclaimerPlatforms
              disclaimer={disclaimer}
              setDirty={setDirty}
              setDisclaimerPlatforms={setDisclaimers}
              deleteDisclaimer={() => deleteDisclaimer(disclaimer.id)}
            />
          ))}
        <button
          className="w-full flex items-center justify-center"
          onClick={() => addDisclaimer()}
        >
          <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
            <CheckBoxReferralIcon />
            <span className="text-[10px] text-influentia-light-blue font-medium">
              {t('singleCampaign.addDisclaimer')}
            </span>
          </div>
        </button>
      </div>
      <div className="flex flex-col gap-1 mt-5">
        <label className="my-2 block text-[14px] font-[600]">
          {t('singleCampaign.description')}
        </label>
        {descriptions &&
          descriptions.map((description) => (
            <DescriptionPlatforms
              description={description}
              setDirty={setDirty}
              setDescriptionPlatforms={setDescriptions}
              deleteDescription={() => deleteDescription(description.id)}
            />
          ))}
        <button
          className="w-full flex items-center justify-center"
          onClick={() => addDescription()}
        >
          <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
            <CheckBoxReferralIcon />
            <span className="text-[10px] text-influentia-light-blue font-medium">
              {t('singleCampaign.addDescription')}
            </span>
          </div>
        </button>
      </div>
      <div className="mt-5">
        <Button
          style="light-blue"
          onClick={() => handleSave()}
          text={t('global.save')}
          loading={loading}
          customClassnames="px-16"
        />
      </div>
    </div>
  );
};
