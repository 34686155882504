import { useEffect, useState } from 'react';
import type { UseFormRegister } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';

import { TooltipIcon } from '../../assets';
import { Error } from '../common/Error';

type Props = {
  label?: string;
  selectedValue?: string | number | null;
  onSelectChange?: (value: string | number) => void;
  onBlur?: (e?: any) => void;
  placeholder?: string;
  type: string;
  register?: UseFormRegister<any>;
  registerName?: string;
  validation?: any;
  errors?: any;
  icon?: any;
  id?: string;
  max?: number;
  extraClassname?: string;
  maxLength?: number;
  disabled?: boolean;
  labelClassname?: string;
  tooltipText?: string;
};

export const TextInput = ({
  selectedValue,
  onSelectChange,
  onBlur,
  label,
  placeholder,
  type,
  register,
  registerName,
  validation,
  errors,
  icon,
  max,
  extraClassname,
  maxLength,
  disabled,
  labelClassname,
  tooltipText,
  id,
}: Props) => {
  const [inputValue, setInputValue] = useState<string | number>(
    selectedValue || '',
  );

  useEffect(() => {
    if (
      selectedValue !== null &&
      selectedValue !== undefined &&
      selectedValue !== inputValue
    )
      setInputValue(selectedValue as string | number);
  }, [selectedValue]);

  if (type === 'checkbox') {
    return (
      <div className="flex flex-row items-center gap-x-2 text-[14px]">
        <input
          checked={inputValue === 'true'}
          onChange={() =>
            onSelectChange &&
            onSelectChange(inputValue === 'true' ? 'false' : 'true')
          }
          // onClick={() => onSelectChange && onSelectChange(inputValue)}
          type="checkbox"
          name={(label || '') + (placeholder || '')}
          id={label?.replace(/\s/g, '') || ''}
          className="checkbox flex size-[18px] items-center justify-center rounded-md border bg-white  focus:ring-0 focus:ring-transparent focus:ring-offset-0"
        />
        <label
          className={`${labelClassname}`}
          htmlFor={(label || '') + (placeholder || '')}
        >
          {label}
        </label>
      </div>
    );
  }
  const INPUT_CLASSNAME = `${icon ? 'pr-3 pl-9' : 'px-3'} ${errors ? 'border border-red-600' : 'border-none'} ${extraClassname} block h-[41px] w-full py-[8px] text-sm font-medium placeholder:text-[#5A5A5A] focus:outline-none focus:ring-0 rounded-[4px]`;
  return (
    <div className="w-full">
      <div className="flex flex-row">
        {label && (
          <label
            htmlFor={label}
            className={`my-2 block text-[14px] font-[600] ${labelClassname}`}
          >
            {label}
          </label>
        )}
        <div
          data-tooltip-id={`tooltip-${label}`}
          data-tooltip-content={tooltipText}
          className={`flex items-center justify-center ml-2 ${tooltipText ? 'cursor-pointer' : 'hidden'}`}
        >
          <TooltipIcon className="text-influentia-light-blue cursor-pointer" />
          <Tooltip
            className="!bg-dark-blue !opacity-100 !text-white font-montserrat !p-1 !text-sm rounded-10 max-w-[300px] text-center z-50"
            noArrow
            id={`tooltip-${label}`}
            place="bottom"
          />
        </div>
      </div>
      <div
        className={`relative flex w-full items-center text-dark-blue flex-col`}
      >
        <div className="flex items-center justify-start w-full">
          {register && registerName ? (
            <input
              maxLength={maxLength || (type === 'number' ? 15 : undefined)}
              type={type}
              disabled={!!disabled}
              id={id || label?.replace(/\s/g, '')}
              className={INPUT_CLASSNAME}
              placeholder={placeholder || label || ''}
              {...(register &&
                registerName &&
                register(registerName, validation))}
              autoComplete="off"
            />
          ) : (
            <input
              value={inputValue || ''}
              disabled={!!disabled}
              onChange={(e) => {
                if (type === 'number' && e.target.value.length > 8) {
                  return;
                }
                if (
                  max &&
                  type === 'number' &&
                  (Number(e.target.value) > max || Number(e.target.value) < 0)
                ) {
                  return;
                }
                setInputValue(e.target.value);
                if (onSelectChange) onSelectChange(e.target.value);
              }}
              onBlur={onBlur}
              maxLength={maxLength || (type === 'number' ? 15 : undefined)}
              type={type}
              id={label}
              className={INPUT_CLASSNAME}
              placeholder={placeholder || label || ''}
              autoComplete="off"
            />
          )}

          <div className="absolute left-3">{icon}</div>
        </div>
        <div className="mt-1 w-full">
          {errors && registerName && <Error error={errors.message} />}
        </div>
      </div>
    </div>
  );
};
