import { ErrorFeedback } from '../../assets';

export const Warning = ({
  warning,
  rightText,
}: {
  warning: string;
  rightText?: string;
}) => {
  return (
    <div className="bg-influentia-yellow py-1 w-full flex items-center rounded-[5px] border border-[#E59232] text-dark-blue font-inter text-sm pr-1 flex-row justify-between">
      <div className="flex">
        <div>
          <ErrorFeedback className="mx-1" />
        </div>
        {warning}
      </div>
      <span>{rightText}</span>
    </div>
  );
};
