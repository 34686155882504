import { gql } from '@apollo/client';

export const MARK_ALL_NOTIFICATIONS_READ = gql`
  mutation SetAllNotificationReaded {
    setAllNotificationReaded {
      status
      code
      data
      error
    }
  }
`;

export const MARK_NOTIFICATION_READ = gql`
  mutation SetNotificationReaded($id: String!) {
    setNotificationReaded(id: $id) {
      status
      code
      data
      error
    }
  }
`;

export const REGISTER_NOTIFICATION_PUSH = gql`
  mutation RegisterNotificationPush($permissions: String) {
    registerNotificationPush(permissions: $permissions) {
      status
      code
      data
      error
    }
  }
`;

export const STOP_NOTIFICATIONS = gql`
  mutation StopNotifications($campaign_id: String!, $user_id: String!) {
    stopNotifications(campaign_id: $campaign_id, user_id: $user_id) {
      status
      code
      data
      error
    }
  }
`;
