import { useTranslation } from 'react-i18next';

import { Link } from '../../../assets';

export function PublicationLink({ piece }: { piece: { link: string } }) {
  const { t } = useTranslation('common');
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row gap-[6px] items-center w-full">
        <div className="bg-white size-[28px] rounded-full text-influentia-black flex items-center justify-center">
          <Link />
        </div>
        <div className="flex flex-col w-full">
          <span className="text-influentia-light-blue text-[10px] font-medium">
            {t('singleCampaign.link')}
          </span>
          <div className="flex flex-row justify-between w-full">
            <a
              href={piece.link}
              target="_blank"
              className="text-sm font-medium text-white hover:text-influentia-light-blue"
            >
              {piece.link}
            </a>
            <div className="flex items-center justify-center">
              <a
                href={piece.link}
                target="_blank"
                className="text-sm font-medium text-white hover:text-influentia-light-blue"
              >
                <Link />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
