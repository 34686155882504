import { useTranslation } from 'react-i18next';

import type { CampaignTableSubrow } from '../../types';
import { formatNumberMoney } from '../../utils/numberFormatter';
import { CAMPAIGN_TABLE_SUBROWS } from '../tables/headers/campaigns';

export const SubrowTableRecurrent = ({
  pieces,
  user_id,
  name,
  platforms,
  price,
  publicationDate,
  status,
  profile_picture,
  variable_type,
}: CampaignTableSubrow) => {
  const { t } = useTranslation('common');
  return (
    <>
      <div className="cell-class truncate px-3 rounded-l-10 text-start border-influentia-black w-full flex-1">
        <div className="flex justify-start pl-[30px]">
          {CAMPAIGN_TABLE_SUBROWS.influencer.cell(
            name,
            user_id,
            profile_picture,
          )}
        </div>
      </div>
      <div className="cell-class truncate px-3 flex-1 w-full">
        <div className="flex justify-center">
          {CAMPAIGN_TABLE_SUBROWS.pieces.cell(
            `${pieces.toString()} ${pieces === 1 ? t('campaigns.actionsSubrowSingular') : t('campaigns.actionsSubrowPlural')}`,
          )}
        </div>
      </div>
      <div className="cell-class truncate px-3 flex-1 w-full">
        <div className="flex justify-center">
          {CAMPAIGN_TABLE_SUBROWS.status.cell(status)}
        </div>
      </div>
      <div className="cell-class truncate px-3 flex-1 w-full">
        <div className="flex justify-center">
          {CAMPAIGN_TABLE_SUBROWS.price.cell(
            `${formatNumberMoney(price)}${variable_type && variable_type !== 'No' ? ` + ${t('singleCampaign.variablePricing')}` : ''}`,
          )}
        </div>
      </div>
      <div className="cell-class truncate px-3 flex-1 w-full">
        <div className="flex justify-end">
          {CAMPAIGN_TABLE_SUBROWS.platforms.cell(platforms)}
        </div>
      </div>
    </>
  );
};
