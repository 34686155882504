import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrashIcon } from '../../../../assets';
import { Confirm } from '../../../../context/confirm/confirm';
import { TextInput } from '../../../inputs/TextInput';
import PlatformIcons from './PlatformsIcons';

export default function ReferralCode({
  referral,
  updateReferral,
  deleteReferral,
}: {
  referral: {
    id: string;
    type: string;
    referral: string;
    platforms: string;
  };
  updateReferral: (key: string, val: any) => void;
  deleteReferral: any;
}) {
  const { t } = useTranslation('common');

  const onClickPlatform = (platform: string) => () => {
    const { platforms } = referral;
    const oldPlatforms = platforms.split(',').filter((p) => p);
    const i = oldPlatforms.findIndex((p) => p === platform);

    if (i !== -1) oldPlatforms.splice(i, 1);
    else oldPlatforms.push(platform);

    updateReferral('platforms', oldPlatforms.join(','));
  };

  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-row gap-3 items-center">
      <Confirm
        open={open}
        onConfirm={() => deleteReferral()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />
      <div className="w-full">
        <div className="flex flex-row gap-2.5">
          <TextInput
            selectedValue={referral.referral}
            type="text"
            maxLength={200}
            onBlur={(e) => {
              updateReferral('referral', String(e.target.value));
            }}
            extraClassname="!h-[34px]"
          />
          <PlatformIcons
            platforms={referral.platforms.split(',')}
            onClickPlatform={onClickPlatform}
          />
        </div>
      </div>
      <div className="flex items-center justify-center w-px h-4 bg-dark-blue"></div>
      <div
        className={`flex items-center justify-center cursor-pointer`}
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
      </div>
    </div>
  );
}
