import React from 'react';
import { useTranslation } from 'react-i18next';

import type { PieceStatusType, PlatformType } from '../../../types';
import type { ActionType } from '../../../types/action';
import { UsersImages } from '../../images/UserImages';
import { ActionTypeCom } from './ActionType';
import { PublicationLink } from './PublicationLink';

type PublishedActionsProps = {
  profilePicture: string;
  pieces: {
    id: string;
    type: ActionType;
    status: PieceStatusType;
    platform: PlatformType;
    description: string;
    link: string;
  }[];
  name: string;
};

export function PublishedActions({
  profilePicture,
  pieces,
  name,
}: PublishedActionsProps) {
  const { t } = useTranslation('common');

  // Check if any piece has a link
  const hasLink = pieces.some((piece) => piece.link);

  // If no piece has a link, do not render the component
  if (!hasLink) {
    return null;
  }

  return (
    <>
      {pieces.length > 0 && (
        <div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-[5px]">
              <UsersImages
                userCount={1}
                userImages={[profilePicture]}
                size="size-[24px]"
              />
              <div className="flex items-center justify-center">
                <span className="text-[14px] font-bold">{name}</span>
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-col gap-[5px]">
            {pieces.map((piece, index) => {
              // Only render pieces that have a link
              if (piece.link) {
                return (
                  <React.Fragment key={piece.id}>
                    <ActionTypeCom
                      showStatus={false}
                      extraActions={false}
                      index={index + 1}
                      totalActions={pieces.length}
                      pieceId={piece.id}
                      actionStatus={piece.status}
                      platform={piece.platform}
                      type={piece.type}
                    />
                    <PublicationLink piece={piece} />
                  </React.Fragment>
                );
              }
              return null; // Return null if piece.link does not exist
            })}
            <div className="bg-white h-px w-full mt-[15px]"></div>
          </div>
        </div>
      )}
    </>
  );
}
