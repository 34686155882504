import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { ROUTES } from '../../../config/routes/config';
import { RefetchLateral } from '../../../context/refetchLateral/refetchProvider';
import {
  GET_INFLUENCER_CAMPAIGN_GENERAL,
  GET_SINGLE_CAMPAIGN_MAIN_INFO,
} from '../../../graphql/campaign/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import type {
  InfluencerCampaignGeneral,
  ResInfluencerCampaignGeneral,
  ResSingleCampaignType,
  SingleCampaignMainInfo,
} from '../../../types';
import {
  downloadDocumentClick,
  getNoteType,
  getSegmentBeforeSecondUUID,
} from '../../../utils/utils';
import { Button } from '../../common/Button';
import { ActionInfo } from '../content/ActionInfo';
import { PendingActions } from '../content/PendingAction';
import { PublishedActions } from '../content/PublishedAction';
import { TodayPublication } from '../content/TodayPublication';
import { InternalNotes } from '../InternalNotes';
import CopyText from '../resources/CopyText';
import { ScheduledDate } from '../ScheduledDate';
import { CampaignInfluencers } from '../Sidebar/CampaignInfluencers';
import { CampaignInfluencersPrice } from '../Sidebar/CampaignInfluencesPrice';
import { CampaignPrice } from '../Sidebar/CampaignPrice';
import { HiredPlatforms } from '../Sidebar/HiredPlatforms';
import { UserInfoCard } from '../UserInfoCard';

export const SingleCampaignGeneralTab = () => {
  const [campaignId, influencerId, brandId]: [string, string, string] =
    useOutletContext();
  const { id } = useParams();
  const [campaignInfo, setCampaignInfo] = useState<SingleCampaignMainInfo>();

  const [generalInfo, setGeneralInfo] = useState<InfluencerCampaignGeneral>();
  const { t } = useTranslation('common');
  const { getAccessTokenSilently } = useAuth0();
  const { notifyWarning } = useNotification();

  const { refetchTabs } = useContext(RefetchLateral);
  const [referralKey, setReferral] = useState(0);
  const { isInfluencer, isBrand, isInfluentia } = usePermissions();
  const location = getSegmentBeforeSecondUUID(window.location.pathname) || '';

  const { refetch } = useQuery<ResInfluencerCampaignGeneral>(
    GET_INFLUENCER_CAMPAIGN_GENERAL,
    {
      variables: {
        campaignId,
        influencerId,
        type: getNoteType(
          location,
          isInfluencer,
          isBrand,
          isInfluentia,
          id === brandId,
        ),
      },
      skip: !influencerId || !campaignId || influencerId === 'campaign',
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setReferral((prev) => prev + 1);
        setGeneralInfo(data.influencerCampaignGeneral.data);
      },
    },
  );

  const navigate = useNavigate();

  const { refetch: refectMainInfo } = useQuery<ResSingleCampaignType>(
    GET_SINGLE_CAMPAIGN_MAIN_INFO,
    {
      skip: influencerId === id,
      variables: {
        campaignId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (!data.campaign.data) {
          navigate(ROUTES.CAMPAIGNS);
          notifyWarning(t('global.errorCampaign'));
          return;
        }
        setCampaignInfo(data.campaign.data);
      },
      onError: () => {
        notifyWarning(t('global.errorCampaign'));
        navigate(ROUTES.CAMPAIGNS);
      },
    },
  );
  const [loading, setLoading] = useState(false);

  function getTodayDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure month is 2 digits
    const day = String(date.getDate()).padStart(2, '0'); // Ensure day is 2 digits

    return `${year}-${month}-${day}`;
  }

  // check if we renders the components or not.
  const { totalPendingActions, publishingToday, totalPiecesWithLink } =
    campaignInfo?.influencers.reduce(
      (accumulator, influencer) => {
        if (Array.isArray(influencer.pieces)) {
          // plus the total pending actions
          accumulator.totalPendingActions += influencer.pieces.length;

          // plus the total pieces that are publishing today
          accumulator.publishingToday += influencer.pieces.filter(
            (piece) => piece.publishingAt === getTodayDate(),
          ).length;

          // plus the total pieces that have a link
          accumulator.totalPiecesWithLink += influencer.pieces.filter(
            (piece) => piece.link,
          ).length;
        }
        return accumulator;
      },
      {
        totalPendingActions: 0,
        publishingToday: 0,
        totalPiecesWithLink: 0,
      },
    ) || {
      totalPendingActions: 0,
      publishingToday: 0,
      totalPiecesWithLink: 0,
    };

  return (
    <>
      {/* influencers tabs */}
      {(id !== 'campaign' || isInfluencer()) && (
        <div>
          <div className="flex flex-col lg:flex-row">
            <div className="w-full">
              <div className="bg-dark-blue p-5 flex flex-col gap-3 rounded-10 w-full">
                <div key={referralKey}>
                  {generalInfo && (
                    <UserInfoCard
                      showDate
                      campaignId={campaignId}
                      influencerId={influencerId}
                      name={generalInfo.name}
                      date={generalInfo.publicationDate}
                      refetch={refetch}
                      refetchTabs={refetchTabs}
                      status={generalInfo.status}
                      image={generalInfo.profilePicture}
                      isBrand={brandId === influencerId || isInfluencer()}
                      influencerSidebar
                    />
                  )}
                </div>
                {generalInfo && (
                  <>
                    {isInfluencer() && (
                      <CampaignPrice
                        price={generalInfo.amount}
                        currency={generalInfo.currency}
                        variableType={
                          campaignInfo?.influencers[0].variable_type
                        }
                        variableAmount={
                          campaignInfo?.influencers[0].variable_amount
                        }
                        actions={campaignInfo?.influencers[0].actions}
                      />
                    )}

                    <HiredPlatforms platforms={generalInfo.platforms} />
                    {generalInfo.briefing && (
                      <Button
                        text={t('global.downloadBriefing')}
                        style="light-blue"
                        loading={loading}
                        customClassnames={`w-full items-center flex justify-center hover:!bg-influentia-black`}
                        onClick={async () => {
                          if (generalInfo.briefing && !loading) {
                            try {
                              const accessToken =
                                await getAccessTokenSilently();
                              await downloadDocumentClick(
                                String(generalInfo.briefing),
                                accessToken,
                                setLoading,
                              );
                            } catch (error) {
                              setLoading(false);
                            }
                          }
                        }}
                      />
                    )}
                    {!generalInfo.briefing && (
                      <span className="flex items-center justify-center text-center italic text-sm">
                        {t('global.noBriefing')}
                      </span>
                    )}
                  </>
                )}
              </div>
              {generalInfo && (
                <div className="mt-7">
                  <InternalNotes
                    campaignId={campaignId}
                    influencerId={influencerId}
                    type={
                      isBrand() ? 'brand-influentia' : 'influencer-influentia'
                    }
                  />
                </div>
              )}
            </div>
            {generalInfo &&
              generalInfo.pieces &&
              generalInfo.pieces.length > 0 && (
                <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 lg:min-w-[500px] self-start w-full max-w-[900px]">
                  <span className="font-medium text-sm uppercase">
                    {t('global.actions')}
                  </span>
                  <div className="grow-1 flex flex-col gap-5">
                    {generalInfo.pieces.map((piece, index) => (
                      <ActionInfo
                        key={index}
                        piece={piece}
                        index={index + 1}
                        refetch={refetch}
                        totalActions={generalInfo.pieces.length}
                      />
                    ))}
                  </div>
                  <div className={`${!isInfluencer() && 'hidden'}`}>
                    {generalInfo &&
                      generalInfo.descriptions &&
                      generalInfo.descriptions.map((description, index) => (
                        <CopyText
                          key={index}
                          type="Description"
                          text={description.description}
                        />
                      ))}
                    {generalInfo &&
                      generalInfo.disclaimers &&
                      generalInfo.disclaimers.map((disclaimer, index) => (
                        <CopyText
                          key={index}
                          type="Disclaimer"
                          text={disclaimer.disclaimer}
                        />
                      ))}
                    {generalInfo &&
                      generalInfo.referrals &&
                      generalInfo.referrals.map((referral, index) => (
                        <CopyText
                          key={index}
                          type={referral.type}
                          text={referral.referral}
                        />
                      ))}
                  </div>
                </div>
              )}
            {generalInfo &&
              generalInfo.pieces &&
              generalInfo.pieces.length === 0 && (
                <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 lg:min-w-[500px] self-start w-full max-w-[900px]">
                  <span className="font-medium text-sm uppercase">
                    {t('global.actions')}
                  </span>
                  <span className="flex items-center justify-center text-center italic text-sm">
                    {t('global.noActions')}
                  </span>
                </div>
              )}
            {generalInfo &&
              generalInfo.dates &&
              generalInfo.dates.length > 0 && (
                <div className="bg-dark-blue p-5 flex flex-col gap-2 lg:ml-5 lg:mt-0 mt-5 rounded-10 self-start min-w-[290px] w-full">
                  <span className="font-medium text-sm uppercase">
                    {t('global.events')}
                  </span>
                  {generalInfo.dates.map((date, index) => (
                    <ScheduledDate
                      key={index}
                      type={date.type}
                      date={date.date}
                    />
                  ))}
                </div>
              )}
            {generalInfo &&
              generalInfo.dates &&
              generalInfo.dates.length === 0 && (
                <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 self-start min-w-[290px] w-full">
                  <span className="font-medium text-sm uppercase">
                    {t('global.events')}
                  </span>
                  <span className="flex items-center justify-center text-center italic text-sm">
                    {t('global.noEvents')}
                  </span>
                </div>
              )}
          </div>
        </div>
      )}
      {/* Brand and influentia general tab */}
      {id === 'campaign' && !isInfluencer() && (
        <div className="flex flex-col lg:flex-row">
          <div className="w-full">
            <div className="bg-dark-blue p-5 flex flex-col gap-3 rounded-10 w-full">
              <div key={referralKey}>
                {campaignInfo && (
                  <UserInfoCard
                    influencerSidebar={false}
                    campaignId={campaignId}
                    influencerId={influencerId}
                    name={campaignInfo?.brand_name || ''}
                    status=""
                    recurrent={false}
                    date={''}
                    showDate={false}
                    campaignStatus={campaignInfo?.status!}
                    campaignPaused={campaignInfo?.paused}
                    refetch={refetch}
                    refetchTabs={() => {
                      refetchTabs();
                      refectMainInfo();
                    }}
                    image={campaignInfo?.brand_profile_picture}
                    isBrand={isBrand()}
                  />
                )}
              </div>
              {generalInfo && campaignInfo && (
                <>
                  <CampaignPrice
                    price={campaignInfo.money}
                    currency={campaignInfo.currency}
                    variable={campaignInfo.influencers.some(
                      (i) => i.variable_type && i.variable_type !== 'No',
                    )}
                  />
                  <HiredPlatforms platforms={generalInfo.platforms} />
                  <CampaignInfluencers influencers={campaignInfo.influencers} />
                  {generalInfo.briefing && (
                    <Button
                      text={t('global.downloadBriefing')}
                      style="light-blue"
                      loading={loading}
                      customClassnames={`w-full items-center flex justify-center hover:!bg-influentia-black`}
                      onClick={async () => {
                        if (generalInfo.briefing && !loading) {
                          try {
                            const accessToken = await getAccessTokenSilently();
                            await downloadDocumentClick(
                              String(generalInfo.briefing),
                              accessToken,
                              setLoading,
                            );
                          } catch (error) {
                            setLoading(false);
                          }
                        }
                      }}
                    />
                  )}
                  {!generalInfo.briefing && (
                    <span className="flex items-center justify-center text-center italic text-sm">
                      {t('global.noBriefing')}
                    </span>
                  )}
                </>
              )}
            </div>
            {campaignInfo && (
              <div className="mt-7">
                <CampaignInfluencersPrice
                  influencers={campaignInfo.influencers}
                />
              </div>
            )}
            {campaignInfo && (
              <div className="mt-7">
                <InternalNotes
                  campaignId={campaignId}
                  influencerId={influencerId}
                  type="brand-influentia"
                />
              </div>
            )}
          </div>
          {/* Pending actions for publication */}
          {campaignInfo &&
            campaignInfo.influencers.length > 0 &&
            totalPendingActions > 0 && (
              <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 lg:min-w-[500px] self-start w-full">
                <span className="font-medium text-sm uppercase">
                  {t('singleCampaign.pendingActions')}
                </span>
                <div className="grow-1 flex flex-col gap-5">
                  {campaignInfo.influencers.map((influencer, index) => (
                    <PendingActions
                      pieces={influencer.pieces}
                      status={influencer.status}
                      key={index}
                      name={influencer.name}
                      profilePicture={influencer.profile_picture}
                    />
                  ))}
                </div>
              </div>
            )}
          {totalPendingActions === 0 && (
            <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 lg:min-w-[500px] self-start w-full">
              <span className="font-medium text-sm uppercase">
                {t('singleCampaign.pendingActions')}
              </span>
              <span className="flex items-center justify-center text-center italic text-sm">
                {t('singleCampaign.noActions')}
              </span>
            </div>
          )}
          {/* Pending actions for publication */}
          <div className="flex flex-col md:gap-7 gap-1 w-full">
            {campaignInfo &&
              campaignInfo.influencers.length > 0 &&
              publishingToday > 0 && (
                <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 lg:min-w-[300px] self-start w-full">
                  <span className="font-medium text-sm uppercase">
                    {t('singleCampaign.publicationsForToday')}
                  </span>
                  <div className="grow-1 flex flex-col gap-1">
                    {campaignInfo.influencers.map((influencer, index) => (
                      <TodayPublication
                        todayDate={getTodayDate()}
                        pieces={influencer.pieces}
                        key={index}
                        name={influencer.name}
                        profilePicture={influencer.profile_picture}
                      />
                    ))}
                  </div>
                </div>
              )}
            {publishingToday === 0 && (
              <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 lg:min-w-[300px] 2xl:w-min-[500px] self-start w-full">
                <span className="font-medium text-sm uppercase">
                  {t('singleCampaign.publicationsForToday')}
                </span>
                <span className="flex items-center justify-center text-center italic text-sm">
                  {t('singleCampaign.noPublicationsForToday')}
                </span>
              </div>
            )}
            {campaignInfo &&
              campaignInfo.influencers.length > 0 &&
              totalPiecesWithLink > 0 && (
                <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 self-start min-w-[290px] w-full">
                  <span className="font-medium text-sm uppercase">
                    {t('singleCampaign.actionsPublished')}
                  </span>
                  <div className="grow-1 flex flex-col gap-1">
                    {campaignInfo.influencers.map((influencer, index) => (
                      <PublishedActions
                        pieces={influencer.pieces}
                        key={index}
                        name={influencer.name}
                        profilePicture={influencer.profile_picture}
                      />
                    ))}
                  </div>
                </div>
              )}
            {totalPiecesWithLink === 0 && (
              <div className="bg-dark-blue p-5 flex flex-col gap-5 lg:ml-5 lg:mt-0 mt-5 rounded-10 self-start min-w-[290px] w-full">
                <span className="font-medium text-sm uppercase">
                  {t('singleCampaign.actionsPublished')}
                </span>
                <span className="flex items-center justify-center text-center italic text-sm">
                  {t('singleCampaign.noActionsPublished')}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

/*

*/
