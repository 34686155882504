import { useTranslation } from 'react-i18next';

import type { AllStatusType } from '../../types';

const STATUS_COLOR = {
  not_send: 'bg-gray-status text-dark-blue',
  analysis: 'bg-orange-status text-dark-blue',
  paused: 'bg-orange-status-pause text-white',

  tobe_scheduled: 'bg-pink-status text-white',
  script_upload: 'bg-gray-status text-dark-blue',
  script_validation: 'bg-influentia-light-blue text-dark-blue',
  preview_upload: 'bg-gray-status text-dark-blue',
  preview_validation: 'bg-influentia-light-blue text-dark-blue',
  scheduled: 'bg-purple-status text-white',
  published: 'bg-green-status text-dark-blue',

  upload_pending: 'bg-gray-status text-dark-blue',
  internal_validation: 'bg-influentia-yellow text-dark-blue',
  external_validation: 'bg-influentia-light-blue text-dark-blue',
  modification_pending: 'bg-gray-status text-dark-blue',
  validated: 'bg-green-status text-dark-blue',
  old: 'bg-gray-status text-dark-blue',

  // Guion y Video
  payment_pending: 'bg-influentia-light-blue text-black',
  paid: 'bg-green-status text-dark-blue',

  // Campaña General
  active: 'bg-blue-status text-white',
  facturation: 'bg-violet-status text-white',
  pending: 'bg-gray-status text-dark-blue',
  declined: 'bg-red-status text-white',
  finished: 'bg-green-status text-dark-blue',
  canceled: 'bg-red-status text-white',
};

interface StatusTextProps {
  status: AllStatusType;
  extraClassnames?: string;
  type?: 'campaign' | 'influencer' | 'content' | 'invoices' | 'action';
}

export const StatusText = ({
  status,
  extraClassnames,
  type,
}: StatusTextProps) => {
  const { t } = useTranslation('common');
  return (
    <div
      className={`h-[24px] text-sm  w-max px-3 rounded-[4px] flex items-center justify-center ${extraClassnames} ${STATUS_COLOR[status as keyof typeof STATUS_COLOR]}`}
    >
      {t(`${type}Status.${status}`)}
    </div>
  );
};
