import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { CalendarIcon, CheckBoxReferralIcon } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { RefetchLateral } from '../../../context/refetchLateral/refetchProvider';
import { UPDATE_INFLUENCER_SETTINGS_RECURRENT } from '../../../graphql/campaign/mutation';
import { GET_INFLUENCER_SETTINGS_RECURRENT } from '../../../graphql/campaign/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePrompt } from '../../../hooks/usePrompt';
import type { TInfluencerSettingsRecurrentForm } from '../../../types';
import { Button } from '../../common/Button';
import { DatePickerInput } from '../../inputs/DatePicker';
import { Toggle } from '../../inputs/Toggle';
import ActionSettingsRecurrent from '../recurrents/ActionSettingsRecurrent';
import { MonthActionRecurrent } from '../recurrents/MonthActionRecurrent';
import { CampaignPriceSections } from '../settings/CampaignPriceSection';
import ReferralCode from '../settings/influencer/ReferralCode';

export const SingleCampaignRecurrentSettingsInfluencerTab = () => {
  const [campaignId, influencerId]: [string, string] = useOutletContext();
  const { refetchLateral, refetchTabs } = useContext(RefetchLateral);
  const { t } = useTranslation('common');
  const [months, setMonths] = useState<any>([]);
  const [confirmPopupCicle, setConfirmPopupCicle] = useState<boolean>(false);
  const { notifyError } = useNotification();

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    watch,
    register,
    formState: { dirtyFields, errors },
  } = useForm<TInfluencerSettingsRecurrentForm>();
  const { cicle, status } = watch();

  const {
    fields: actions,
    append: addAction,
    remove: removeAction,
    update: updateAction,
  } = useFieldArray({ control, name: 'actions', keyName: 'rowId' });

  const {
    fields: referrals,
    append: addReferral,
    remove: removeReferral,
    update: updateReferral,
  } = useFieldArray({ control, name: 'referrals', keyName: 'rowId' });

  const { refetch, loading: loadingInfo } = useQuery(
    GET_INFLUENCER_SETTINGS_RECURRENT,
    {
      variables: {
        influencerId,
        campaignId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const info: TInfluencerSettingsRecurrentForm =
          data.influencerCampaignRecurrentSettings.data;
        reset(info);
        let currentMonths = Array.from(
          new Set(info.actions.map((action) => action.month)),
        );
        if (currentMonths.length === 0) currentMonths = [1];
        setMonths(currentMonths);
      },
    },
  );
  const [updateInfluencerSettings, { loading }] = useMutation(
    UPDATE_INFLUENCER_SETTINGS_RECURRENT,
  );
  const [updateInfluencerSettings2, { loading: loading2 }] = useMutation(
    UPDATE_INFLUENCER_SETTINGS_RECURRENT,
  );
  const { notifySaveSuccess, notifySaveError } = useNotification();

  const handleNewReferral = (type: string) => {
    addReferral({
      id: Math.floor(Math.random() * 1000).toString(),
      type,
      referral: '',
      platforms: '',
    });
  };
  const handleNewAction = (month: number) => {
    addAction({
      id: Math.floor(Math.random() * 1000).toString(),
      type: 'Dedicated_Video',
      platform: 'Youtube',
      description: '',
      month: month || 1,
      number_of_action: 1,
      hasScript: true,
      hasPreview: true,
      disabled: false,
    });
  };
  const handleNewMonth = () => {
    setMonths((prevMonths: any) => {
      return [...prevMonths, Math.max(...prevMonths) + 1];
    });
  };
  const removeMonth = (month: number) => {
    // Update the months array
    setMonths((prevMonths: any) => {
      const newMonths = prevMonths.filter((m: any) => m !== month);
      return newMonths.map((m: any) => (m > month ? m - 1 : m));
    });
    const currentActions = [...watch('actions')];
    currentActions.forEach((a, i) => {
      if (a.month === month) removeAction(i);
      if (a.month > month) updateAction(i, { ...a, month: a.month - 1 });
    });
  };

  const handleSave = async () => {
    if (Number(watch('influencer_amount')) === 0) {
      notifyError(t('singleCampaign.influencerPriceRequired'));
      return;
    }
    if (actions.length === 0) {
      notifyError(t('singleCampaign.actionsRequired'));
      return;
    }

    if (!watch('initDate') || watch('initDate') === null) {
      notifyError(t('singleCampaignRecurrent.initDateRequired'));
      return;
    }

    updateInfluencerSettings({
      variables: {
        influencerId,
        campaignId,
        influencerAmount: Number(watch('influencer_amount')),
        influencerAmountType: watch('influencer_amountType'),
        totalAmount: Number(watch('total_amount')),
        initDate: watch('initDate'),
        finishDate: watch('finishDate'),
        cicle: watch('cicle'),
        total_variable_amount: Number(watch('total_variable_amount')),
        influencer_variable_type: watch('influencer_variable_type'),
        influencer_variable_amount: Number(watch('influencer_variable_amount')),
        referrals: watch('referrals').map((r) => ({ ...r, rowId: undefined })),
        actions: watch('actions').map((action) => ({
          id: action.id,
          type: action.type,
          month: action.month,
          platform: action.platform,
          description: action.description,
          hasScript: action.hasScript,
          hasPreview: action.hasPreview,
          number_of_action: action.number_of_action,
          variable_type: action.variable_type || undefined,
          total_amount: Number(action.total_amount),
          total_currency: action.total_currency,
          influencer_amount: Number(action.influencer_amount),
          influencer_currency: action.influencer_currency,
        })),
      },
    })
      .then(() => {
        notifySaveSuccess();
        refetch();
        reset();
        refetchLateral();
        refetchTabs();
      })
      .catch(() => {
        notifySaveError();
      });
  };

  const handleSaveAndSend = async () => {
    if (Number(watch('influencer_amount')) === 0) {
      notifyError(t('singleCampaign.influencerPriceRequired'));
      return;
    }
    if (actions.length === 0) {
      notifyError(t('singleCampaign.actionsRequired'));
      return;
    }
    if (!watch('initDate') || watch('initDate') === null) {
      notifyError(t('singleCampaignRecurrent.initDateRequired'));
      return;
    }
    updateInfluencerSettings2({
      variables: {
        influencerId,
        send: true,
        status: 'active',
        campaignId,
        influencerAmount: Number(watch('influencer_amount')),
        totalAmount: Number(watch('total_amount')),
        initDate: watch('initDate'),
        finishDate: watch('finishDate'),
        total_variable_amount: Number(watch('total_variable_amount')),
        influencer_variable_type: watch('influencer_variable_type'),
        influencer_variable_amount: Number(watch('influencer_variable_amount')),
        cicle: watch('cicle'),
        referrals: watch('referrals'),
        actions: watch('actions').map((action) => ({
          id: action.id,
          type: action.type,
          month: action.month,
          platform: action.platform,
          description: action.description,
          hasScript: action.hasScript,
          hasPreview: action.hasPreview,
          number_of_action: action.number_of_action,
          variable_type: action.variable_type || undefined,
          total_amount: Number(action.total_amount),
          total_currency: action.total_currency,
          influencer_amount: Number(action.influencer_amount),
          influencer_currency: action.influencer_currency,
        })),
      },
    })
      .then(() => {
        notifySaveSuccess();
        refetch();
        // window.location.reload();
        reset();
        refetchLateral();
        refetchTabs();
      })
      .catch(() => {
        notifySaveError();
      });
  };

  const deleteReferral = (i: number) => {
    removeReferral(i);
  };

  const deleteAction = (i: number) => {
    removeAction(i);
  };

  usePrompt({
    isDirty: Object.keys(dirtyFields).length > 0,
  });

  const onChangeCicle = (val: boolean) => {
    if (val) setValue('cicle', true);
    else {
      setConfirmPopupCicle(true);
      setValue('cicle', false);
    }
  };

  const handleConfirmCicle = () => {
    setMonths([1]);
    const currentActions = [...watch('actions')];
    currentActions.forEach((a, i) => {
      if (a.month !== 1) removeAction(i);
    });
  };

  return (
    <>
      <Confirm
        open={confirmPopupCicle}
        onConfirm={handleConfirmCicle}
        setOpen={setConfirmPopupCicle}
        type="info"
        title={t('singleCampaign.changeCicleTitle')}
        subtitle={t('singleCampaign.changeCicleSubTitle')}
        confirmText={t('singleCampaign.changeCicleConfirm')}
        cancelText={t('global.cancel')}
      />
      {!loadingInfo && (
        <form autoComplete="off" id="recurrentFormInfluencer" className="pb-20">
          <div className="flex lg:flex-row flex-col w-full gap-5 mb-5">
            <div className="flex flex-row w-full">
              <div className="block">
                <DatePickerInput
                  name="initDate"
                  errors={errors.initDate}
                  monthYearPicker
                  isClearable
                  validation={{
                    required: t('singleCampaignRecurrent.initDateRequired'),
                  }}
                  label={t('singleCampaignRecurrent.initDate')}
                  control={control}
                />
              </div>
            </div>
            <div className="flex flex-row w-full">
              <div className="block">
                <DatePickerInput
                  monthYearPicker
                  isClearable
                  errors={errors.finishDate}
                  name="finishDate"
                  tooltipText={t('singleCampaignRecurrent.finishDateTooltip')}
                  label={t('singleCampaignRecurrent.finishDate')}
                  control={control}
                />
              </div>
            </div>
          </div>
          <CampaignPriceSections
            actions={actions}
            control={control}
            influencer_variable_type={watch('influencer_variable_type')}
            watch={watch}
          />
          <div className="flex lg:flex-row flex-col mt-5 gap-8">
            <div className="w-full flex flex-col">
              <span>{t('singleCampaign.referrerCode')}</span>
              <div className="flex flex-row w-full gap-8">
                <div className="w-full flex flex-col gap-1">
                  {referrals.map((referral, i) => {
                    if (referral.type === 'Code') {
                      return (
                        <ReferralCode
                          key={referral.id}
                          referral={referral}
                          updateReferral={(key: string, val: any) =>
                            updateReferral(i, {
                              ...referral,
                              [key]: val,
                            } as any)
                          }
                          deleteReferral={() => deleteReferral(i)}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
              <button
                type="button"
                className="w-full flex items-center justify-center"
                onClick={() => handleNewReferral('Code')}
              >
                <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                  <CheckBoxReferralIcon />
                  <span className="text-[10px] text-influentia-light-blue font-medium">
                    {t('singleCampaign.addCode')}
                  </span>
                </div>
              </button>
            </div>
            <div className="w-full">
              <span>{t('singleCampaign.referrerLink')}</span>
              <div className="flex flex-row w-full gap-8">
                <div className="w-full flex flex-col gap-1">
                  {referrals.map((referral, i) => {
                    if (referral.type === 'Link') {
                      return (
                        <ReferralCode
                          key={referral.id}
                          referral={referral}
                          updateReferral={(key: string, val: any) =>
                            updateReferral(i, {
                              ...referral,
                              [key]: val,
                            } as any)
                          }
                          deleteReferral={() => deleteReferral(i)}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
              <div className="w-full flex items-center justify-center">
                <button type="button" onClick={() => handleNewReferral('Link')}>
                  <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                    <CheckBoxReferralIcon />
                    <span className="text-[10px] text-influentia-light-blue font-medium">
                      {t('singleCampaign.addLink')}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="flex flex-row justify-between">
              <p>{t('global.actions')}</p>
              <div className="mr-10">
                <Toggle
                  label={t('singleCampaignRecurrent.cicle')}
                  value={cicle}
                  onChange={() => onChangeCicle(!cicle)}
                  maxWidth="max-w-[200px]"
                />
              </div>
            </div>
            <div className={`flex flex-col gap-2 ${!cicle && 'hidden'}`}>
              {months.map((month: any, indexMonth: number) => (
                <div className="mt-2" key={month}>
                  <MonthActionRecurrent
                    month={month}
                    removeMonth={removeMonth}
                  />
                  <div className="flex flex-col gap-2 mt-3">
                    {actions.map((action, i) => {
                      if (action.month === month)
                        return (
                          <ActionSettingsRecurrent
                            key={action.id}
                            control={control}
                            action={action}
                            updateAction={(key: string, val: any) => {
                              updateAction(i, {
                                ...action,
                                [key]: val,
                              } as any);
                            }}
                            deleteAction={() => deleteAction(i)}
                            index={i}
                          />
                        );
                      return null;
                    })}
                  </div>
                  <div className="w-full flex items-center justify-center">
                    <button
                      type="button"
                      onClick={() => handleNewAction(month)}
                    >
                      <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                        <CheckBoxReferralIcon />
                        <span className="text-[10px] text-influentia-light-blue font-medium">
                          {t('singleCampaign.addAction')}
                        </span>
                      </div>
                    </button>
                    {indexMonth === months.length - 1 && (
                      <button className="ml-5" onClick={() => handleNewMonth()}>
                        <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                          <CalendarIcon className="text-influentia-light-blue size-[14px]" />
                          <span className="text-[10px] text-influentia-light-blue font-medium">
                            {t('singleCampaignRecurrent.addMonth')}
                          </span>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className={`flex flex-col gap-2 ${cicle && 'hidden'}`}>
              <div className={`flex flex-col gap-2 mt-3 `}>
                {actions.map((action, i) => {
                  if (action.month === 1) {
                    return (
                      <ActionSettingsRecurrent
                        key={action.id}
                        control={control}
                        action={action}
                        updateAction={(key: string, val: any) => {
                          updateAction(i, {
                            ...action,
                            [key]: val,
                          } as any);
                        }}
                        deleteAction={() => deleteAction(i)}
                        index={i}
                      />
                    );
                  }
                  return null;
                })}
              </div>
              <div className="w-full flex items-center justify-center">
                <button type="button" onClick={() => handleNewAction(1)}>
                  <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                    <CheckBoxReferralIcon />
                    <span className="text-[10px] text-influentia-light-blue font-medium">
                      {t('singleCampaign.addAction')}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-5 lg:items-end flex lg:justify-end justify-center w-full flex-row gap-3">
            <Button
              style="light-blue"
              onClick={handleSubmit(handleSave)}
              loading={loading}
              text={t('singleCampaign.updateInfo')}
            />
            {status === 'not_send' && (
              <Button
                style="light-blue"
                onClick={handleSubmit(handleSaveAndSend)}
                loading={loading2}
                text={t('singleCampaignRecurrent.sendProposal')}
              />
            )}
          </div>
        </form>
      )}
    </>
  );
};
