import { DefaultInfluencerIcon } from '../../assets';
import { formatDate } from '../../utils/numberFormatter';

type SingleNoteProps = {
  message: string;
  user: {
    id: string;
    name: string;
    profile_picture: string;
  };
  createdAt: string;
};

export const SingleNote = ({ message, user, createdAt }: SingleNoteProps) => {
  return (
    <div
      id="singleNote"
      className="flex flex-col tracking-influentia md:min-w-[300px]"
    >
      <div className="flex flex-row gap-[10px] items-center mb-1">
        {user && !user.profile_picture && (
          <DefaultInfluencerIcon className="size-[24px]" />
        )}
        {user && user.profile_picture && (
          <img
            src={`${process.env.REACT_APP_AWS_PUBLIC_ENDPOINT}/${user.profile_picture}`}
            className={`rounded-full size-[24px] object-cover`}
          />
        )}
        <span className="font-bold text-sm">{user.name}</span>
      </div>
      <div className="flex flex-col gap-[5px]">
        <span className="text-[10px]">{formatDate(Number(createdAt))}</span>
        <p className="text-xs">{message}</p>
      </div>
    </div>
  );
};
