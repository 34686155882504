import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';

import { useConfirm } from '../context/confirm/confirm.hooks';
import type { ConfirmOptions } from '../types/confirm';

export const usePrompt = ({
  isDirty = false,
  title = 'Tienes cambios sin guardar.',
  subtitle = '¿Estás seguro de que quieres salir sin guardar los cambios?',
  confirmText = 'Salir',
  cancelText = 'Permanecer',
  onConfirm,
  onCancel,
  type = 'warning',
}: ConfirmOptions & { isDirty?: boolean }) => {
  const blocker = useBlocker(isDirty);
  const { t } = useTranslation('common');
  const { show } = useConfirm();

  const confirm = useCallback(() => {
    if (!isDirty) return Promise.resolve(true);

    return new Promise<boolean>((resolve) => {
      show({
        title: t('global.unsavedChanges'),
        subtitle: t('global.unsavedChangesMessage'),
        confirmText: t('global.discard'),
        cancelText: t('global.stay'),
        type,
        onConfirm: () => {
          resolve(true);
          onConfirm?.();
        },
        onCancel: () => {
          resolve(false);
          onCancel?.();
        },
      });
    });
  }, [
    cancelText,
    confirmText,
    isDirty,
    onCancel,
    onConfirm,
    show,
    subtitle,
    title,
    type,
  ]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm().then((result) => {
        if (result) blocker.proceed();
        else blocker.reset();
      });
    }
  }, [blocker, confirm]);

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => subtitle;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty, subtitle]);

  return {
    confirm,
  };
};
