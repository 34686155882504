import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CREATE_INTERNAL_NOTE } from '../../graphql/campaign/mutation';
import { GET_NOTES } from '../../graphql/campaign/query';
import { useNotification } from '../../hooks/useNotification';
import { usePermissions } from '../../hooks/usePermissions';
import type {
  InternalNote,
  ResCreateInternalNote,
  ResponseInternalNote,
} from '../../types';
import { TextArea } from '../inputs/TextArea';
import { SingleNote } from './SingleNote';

type InternalNotesProp = {
  influencerId: string;
  campaignId: string;
  type: 'brand-influentia' | 'influencer-influentia';
};

const SkeletonLoader = () => {
  return (
    <div className="space-y-4 max-h-[245px] overflow-hidden">
      {Array.from({ length: 6 }).map((_, index) => (
        <div className="flex flex-col md:min-w-[300px] animate-pulse">
          {/* Skeleton for the avatar and user name */}
          <div className="flex flex-row gap-[10px] items-center mb-1">
            {/* Skeleton Avatar */}
            <div className="size-[24px] bg-gray-300 rounded-full"></div>
            {/* Skeleton User Name */}
            <div className="w-[100px] h-4 bg-gray-300 rounded"></div>
          </div>
          {/* Skeleton for date and message */}
          <div className="flex flex-col gap-[5px]">
            {/* Skeleton Date */}
            <div className="w-[60px] h-[10px] bg-gray-300 rounded"></div>
            {/* Skeleton Message */}
            <div className="w-full h-4 bg-gray-300 rounded"></div>
            <div className="w-full h-4 bg-gray-300 rounded"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const InternalNotes = ({
  influencerId,
  campaignId,
  type,
}: InternalNotesProp) => {
  const { t } = useTranslation('common');
  const [notes, setNotes] = useState<InternalNote[]>([]);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const { isInfluentia } = usePermissions();
  const influencer = useMemo(
    () => (type === 'brand-influentia' ? undefined : influencerId),
    [type, influencerId],
  );

  const { refetch } = useQuery<ResponseInternalNote>(GET_NOTES, {
    variables: {
      type,
      campaignId,
      influencerId: influencer,
    },
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setNotes(data.notes.data);
    },
  });

  useEffect(() => {
    refetch();
  }, [influencer]);

  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [notes]);
  const [createInternalNote] =
    useMutation<ResCreateInternalNote>(CREATE_INTERNAL_NOTE);

  const createNote = (message: string) => {
    createInternalNote({
      variables: {
        message,
        campaignId,
        influencerId,
        type,
      },
      onCompleted: () => {
        refetch();
        notifySaveSuccess();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  return (
    <div className="bg-dark-blue rounded-10 px-[10px] pt-[10px] ">
      <div className={`mb-[10px] ${isInfluentia() && 'hidden'}`}>
        {t('singleCampaign.internalNotes')}
      </div>
      <div className={`mb-[10px] ${!isInfluentia() && 'hidden'}`}>
        {type === 'brand-influentia' && t('singleCampaign.internalNotesBrand')}
        {type === 'influencer-influentia' &&
          t('singleCampaign.internalNotesInfluencer')}
      </div>
      {notes && notes.length > 0 ? (
        <div
          className="overflow-y-scroll px-1 internalNotes md:min-w-[400px] min-h-[245px] max-h-[245px]"
          ref={scrollRef}
        >
          <div id="internalNotes" className="flex flex-col gap-2">
            {notes.map((note) => (
              <SingleNote key={note.id} {...note} />
            ))}
          </div>
        </div>
      ) : (
        <div className="min-w-[400px] min-h-[245px] px-1 items-center justify-center flex italic font-light text-xs">
          {t('singleCampaign.noNotes')}
        </div>
      )}
      <div className="mt-1">
        <TextArea id="textAreaInternal" send={createNote} />
      </div>
    </div>
  );
};
