import React from 'react';
import { useTranslation } from 'react-i18next';

import type { SingleCampaignInfluencerInfo } from '../../../types';
import { formatNumberMoney } from '../../../utils/numberFormatter';
import { SocialNetworkImage } from '../../images/SocialNetworkImage';
import { UsersImages } from '../../images/UserImages';

type CampaignInfluencersPriceProps = {
  influencers: SingleCampaignInfluencerInfo[];
};

export const CampaignInfluencersPrice = ({
  influencers,
}: CampaignInfluencersPriceProps) => {
  const { t } = useTranslation('common');

  return (
    <div className="bg-dark-blue p-5 flex flex-col gap-3 rounded-10 w-full">
      <div className="flex flex-col gap-[6px]">
        <span className="">
          {t('singleCampaign.influencerVaribalePricing')}
        </span>
        <div className="flex flex-col gap-[5px] divide-y divide-white  text-sm">
          {influencers.map((i) => (
            <div key={i.id + Math.random()} className="py-1 pt-3">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-x-2 items-center">
                  <UsersImages
                    userImages={[i.profile_picture]}
                    userCount={1}
                    influencers
                    size="size-[24px]"
                  />
                  <span className="font-bold">{i.name}</span>
                </div>
                <span className="text-sm text-white">
                  {i.variable_type && i.variable_type !== 'No' ? (
                    <>
                      <span>{t(`varibale_price_type.${i.variable_type}`)}</span>
                      {i.variable_type !== 'Per Action' && (
                        <span>
                          {' - '}
                          {formatNumberMoney(
                            i.variable_amount || 0,
                            undefined,
                            i.currency,
                          )}
                        </span>
                      )}
                    </>
                  ) : (
                    <span>{t('singleCampaign.noVariables')}</span>
                  )}
                </span>
              </div>
              {i.variable_type === 'Per Action' && (
                <div className="flex flex-col self-end items-end mt-2 gap-y-1 text-sm text-white">
                  {i.actions.map((a) => {
                    return (
                      <div className="flex w-full flex-row gap-x-1 items-center justify-between">
                        <div className="flex flex-row items-center gap-x-1">
                          <SocialNetworkImage
                            size="size-[18px]"
                            socialNetwork={a.platform}
                          />
                          {a.platform}
                          {' - '}
                          {t(`actionType.${a.type}`)}
                        </div>
                        <span className=" flex flex-row">
                          {t(`varibale_price_type.${a.variable_type}`)}{' '}
                          {formatNumberMoney(
                            a.variable_amount || 0,
                            undefined,
                            a.variable_currency,
                          )}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
