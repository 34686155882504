import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowReloadIcon, DoubleCheck } from '../../../assets';
import { Confirm } from '../../../context/confirm/confirm';
import { ConfirmUpload } from '../../../context/confirm/confirmUpload';
import { RefetchLateral } from '../../../context/refetchLateral/refetchProvider';
import { UPDATE_PIECE } from '../../../graphql/campaign/mutation';
import { useNotification } from '../../../hooks/useNotification';
import { usePermissions } from '../../../hooks/usePermissions';
import type { PieceStatusType, PlatformType } from '../../../types';
import type { ActionType } from '../../../types/action';
import { StatusText } from '../../common/StatusText';
import { SocialNetworkImage } from '../../images/SocialNetworkImage';

type ActionTypeProps = {
  index: number;
  totalActions: number;
  actionStatus?: PieceStatusType;
  platform: PlatformType;
  type: ActionType;
  iconSize?: string;
  pieceId?: string;
  refetch?: any;
  extraActions?: Boolean;
  showStatus?: Boolean;
};

export function ActionTypeCom({
  index,
  totalActions,
  actionStatus,
  platform,
  type,
  pieceId,
  iconSize = 'size-[16px]',
  refetch,
  extraActions = true,
  showStatus = true,
}: ActionTypeProps) {
  const { isBrand, isInfluentia } = usePermissions();
  const [open, setOpen] = useState(false);
  const [publishedOpen, setPublishedOpen] = useState(false);
  const { t } = useTranslation('common');
  const { refetchTabs, refetchLateral } = useContext(RefetchLateral);

  const [updatePiece] = useMutation(UPDATE_PIECE);
  const { notifySaveSuccess, notifySaveError } = useNotification();
  const handleSave = () => {
    if (!pieceId) return;
    updatePiece({
      variables: {
        pieceId,
        key: 'status',
        value: 'scheduled',
      },
      onCompleted: () => {
        notifySaveSuccess();
        refetch();
      },
      onError: () => {
        notifySaveError();
      },
    });
  };

  return (
    <>
      <ConfirmUpload
        open={open}
        id={pieceId || ''}
        onConfirm={() => {
          refetch();
          refetchLateral();
          refetchTabs();
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
        type="success"
        title={t('contentUpload.confirmTitle')}
        subtitle={t('contentUpload.confirmSubTitle')}
        confirmText={t('contentUpload.confirmButton')}
        cancelText={t('global.cancel')}
      />
      <Confirm
        open={publishedOpen}
        onConfirm={() => handleSave()}
        setOpen={setPublishedOpen}
        type="info"
        title={t('contentUpload.undoconfirmTitle')}
        subtitle={t('contentUpload.undoconfirmSubTitle')}
        confirmText={t('contentUpload.undoconfirmButton')}
        cancelText={t('global.cancel')}
      />
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-1 justify-center">
          <SocialNetworkImage socialNetwork={platform} size={iconSize} />
          <span className="text-white font-medium">
            {t(`actionType.${type}`)} - {index}/{totalActions}
          </span>
        </div>

        {actionStatus && (
          <div className="ml-2">
            <div className="flex flex-row gap-2">
              {actionStatus === 'published' &&
                isInfluentia() &&
                extraActions && (
                  <div
                    onClick={() => setPublishedOpen(true)}
                    className="bg-red-status w-[25px] h-[24px] rounded-[4px] flex items-center justify-center cursor-pointer"
                  >
                    <ArrowReloadIcon />
                  </div>
                )}
              {showStatus && <StatusText type="action" status={actionStatus} />}
              {actionStatus === 'scheduled' && !isBrand() && extraActions && (
                <div
                  onClick={() => setOpen(true)}
                  className="bg-green-status w-[25px] h-[24px] rounded-[4px] flex items-center justify-center cursor-pointer"
                >
                  <DoubleCheck className="text-dark-blue" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
